<template>
    <template v-if="workshop.id" >
        <div class="col-12">
            <Card class='mb-3'>
                <template #title>
                    <h2>
                        {{workshop.title}} 
                    </h2>
                    <p v-html="workshop.body" ></p>
                </template>
                <template #content>
                    <TabView v-model:activeIndex="selectedIndexSurvey" class="mt-5">
                        <TabPanel v-for="question of survey.questions" :key="question.id" :header="question.title">
                            <QuestionRadioAndComment
                                v-if="question.type == 'radio + comment'"
                                :question="question"
                                :answer="answers.filter(answer => answer.questionId == question.id)[0]"
                                :readOnly="!workshop.acl.accesses.write"
                                @patch-answer="(value) => $emit('patch-answer', value, answers.filter(answer => answer.questionId == question.id)[0].id )" />

                            <QuestionRadio
                                v-if="question.type == 'radio'"
                                :question="question"
                                :answer="answers.filter(answer => answer.questionId == question.id)[0]"
                                :readOnly="!workshop.acl.accesses.write"
                                @patch-answer="(value) => $emit('patch-answer', value, answers.filter(answer => answer.questionId == question.id)[0].id)" />

                            <QuestionCheckbox
                                v-if="question.type == 'checkbox'"
                                :question="question"
                                :answer="answers.filter(answer => answer.questionId == question.id)[0]"
                                :readOnly="!workshop.acl.accesses.write"
                                @patch-answer="(value) => $emit('patch-answer', value, answers.filter(answer => answer.questionId == question.id)[0].id )" />

                            <QuestionTextarea
                                v-if="question.type == 'textArea'"
                                :question="question"
                                :answer="answers.filter(answer => answer.questionId == question.id)[0]"
                                :readOnly="!workshop.acl.accesses.write"
                                @patch-answer="(value) => $emit('patch-answer', value, answers.filter(answer => answer.questionId == question.id)[0].id )" />
                        </TabPanel>
                    </TabView>
                </template>
                <template #footer>
                    <div class="flex flex-row-reverse">
                        <Button
                            @click="$emit('submit-workshop')"
                            v-if="selectedIndexSurvey === (survey.questions.length - 1)"
                            :disabled="!workshop.acl.accesses.write || workshopState.progression != 100">
                                {{ $t("evaluation.submit")}}
                        </Button>
                        <Button 
                            @click="switchNextTab"
                            v-if="selectedIndexSurvey !== (survey.questions.length - 1)">
                                {{ $t("evaluation.next")}}
                        </Button>
                    </div>
                </template>
            </Card>
        </div>
    </template>
</template>

<script setup>
import {defineEmits, ref} from 'vue';
import QuestionRadioAndComment from './questions/QuestionRadioAndComment.vue';
import QuestionRadio from './questions/QuestionRadio.vue';
import QuestionCheckbox from './questions/QuestionCheckbox.vue';
import QuestionTextarea from './questions/QuestionTextarea.vue';
import {defineProps } from 'vue'

defineEmits(['patch-answer', 'submit-workshop'])

let props = defineProps({
	workshop: Object,
    workshopState: Object,
    answers: Array,
	assessmentId: String
})

const survey = ref(props.workshop.sections[0].surveys[0])
const selectedIndexSurvey = ref(0)

const switchNextTab = () => {
    selectedIndexSurvey.value = (selectedIndexSurvey.value + 1);
}
</script>
