<template>
<!--    <h5 v-html="question.title"></h5> -->
    <div v-html="question.body"></div>
    <br/>
    
    <Dropdown
        v-model="radioValue[0]"
        :options="question.choices"
        optionLabel="text"
        optionValue="id"
        class="w-full"
        @change="$emit('patch-answer', {content: radioValue, comment: textValue})" />

    <p v-html="question.commentQuestion" class="m-1"></p>

    <Textarea
        v-model="textValue"
        :disabled="readOnly"
        :autoResize="true"
        @change="$emit('patch-answer', {content: radioValue, comment: textValue})"
        rows="2"
        class="w-full" />
</template>

<script setup>

import { ref, defineProps,defineEmits } from 'vue'

defineEmits(["patch-answer"]);

let props = defineProps({
	question: Object,
    answer: Object,
	readOnly: Boolean
})

const textValue = ref(props.answer.comment ?? "")
const radioValue = ref(props.answer.content ?? "")

</script>