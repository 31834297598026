import axios from "axios";

const getCompanyPromise = (token, locale = "EN-CA") => {
  return axios.get("/company", {
    headers: {
      Authorization: "Bearer " + token,
      "Accept-Language": locale,
    },
  });
};

const getParticipationCompanyPromise = (
  token,
  assessmentId,
  locale = "EN-CA"
) => {
  return axios.get(`/company?assessmentId=${assessmentId}`, {
    headers: {
      Authorization: "Bearer " + token,
      "Accept-Language": locale,
    },
  });
};

const getImage = (id, token, locale = "EN-CA") => {
  const request = axios.get(`/asset/${id}/image`, {
    headers: {
      Authorization: "Bearer " + token,
      withCredentials: false,
      "Accept-Language": locale,
    },
  });

  return request.then((res) => res.data);
};

export default {
  getCompanyPromise,
  getParticipationCompanyPromise,
  getImage,
};
