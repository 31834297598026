import axios from "axios";

const getContentByKey = (key, token, locale = "EN-CA") => {
  const request = axios.get(`/contenttext/${key}?type=key`, {
    headers: {
      Authorization: "Bearer " + token,
      "Accept-Language": locale + "-CA",
    },
  });
  return request.then((res) => res.data);
};

export default {
  getContentByKey,
};
