import axios from "axios";

const getAssessmentWithParticipationsForWorkshop = (
  assessmentId,
  workshopId,
  token,
  locale = "EN-CA"
) => {
  const request = axios.get(
    `/assessment/${assessmentId}/participations/workshop/${workshopId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Accept-Language": locale,
      },
    }
  );
  return request.then((res) => res.data);
};

const getAssessment = (token, locale = "EN-CA") => {
  const request = axios.get("/assessment", {
    headers: {
      Authorization: "Bearer " + token,
      "Accept-Language": locale,
    },
  });
  
  return request.then((res) => res.data);
};

const getParticipantAssesmentsList = async (token) => {
  const url = "/assessment/list";
  const response = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return response;
};

export default {
  getAssessment,
  getAssessmentWithParticipationsForWorkshop,
  getParticipantAssesmentsList,
};
