<template>
    <div class="col-12">
        <Card>
            <template #title>
                <h1>{{workshop.title}}</h1>
            </template>
            <template #subtitle>
                <div class="m-3"><ProgressBar :value="parseInt(workshopState.progression)"></ProgressBar></div>
            </template>
            <template #content>
                <!-- <p v-html="workshop.body" ></p> -->
                <div>
                <SectionsNav
                    :sections="workshop.sections"
                    :sectionStates="workshopState.completedSections"
                    :workshopId="workshop.id"
                    :currentSectionId="sectionId"
                    @changeSection="changeSection"
                 />
                </div>
                
                <Paginator :rows="paginator.rows" :totalRecords="paginator.totalRecords" :first="paginator.current" 
                    @page="onPageChange"
                    template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="Capabilities {first} of {totalRecords}" />

                <h5 class="m-1">{{survey.title}}</h5>
                <div v-html="survey.resume" class="m-1"></div>

                <div v-for="question in survey.questions" :key="question.id">
                    <QuestionRadioSelectAndComment
                        v-if="question.type == 'radio + comment'"
                        :question="question"
                        :answer="answers.filter(answer => answer.questionId == question.id)[0]"
                        :readOnly="!workshop.acl.accesses.write"
                        @patch-answer="(value) => $emit('patch-answer', value, answers.filter(answer => answer.questionId == question.id)[0].id)" />

                    <QuestionRadioSelect
                        v-else-if="question.type == 'radio'"
                        :question="question"
                        :answer="answers.filter(answer => answer.questionId == question.id)[0]"
                        :readOnly="!workshop.acl.accesses.write"
                        @patch-answer="(value) => $emit('patch-answer', value, answers.filter(answer => answer.questionId == question.id)[0].id)" />

                    <QuestionCheckbox
                        v-if="question.type == 'checkbox'"
                        :question="question"
                        :answer="answers.filter(answer => answer.questionId == question.id)[0]"
                        :readOnly="!workshop.acl.accesses.write"
                        @patch-answer="(value) => $emit('patch-answer', value, answers.filter(answer => answer.questionId == question.id)[0].id)" />

                    <QuestionTextarea
                        v-if="question.type == 'textArea'"
                        :question="question"
                        :answer="answers.filter(answer => answer.questionId == question.id)[0]"
                        :readOnly="!workshop.acl.accesses.write"
                        @patch-answer="(value) => $emit('patch-answer', value, answers.filter(answer => answer.questionId == question.id)[0].id)" />
                </div>

                
            </template>
        </Card>
    </div>
</template>

<script setup>
import {defineEmits, defineProps, ref, computed} from 'vue';
import QuestionRadioSelectAndComment from './questions/QuestionRadioSelectAndComment.vue';
import QuestionRadioSelect from './questions/QuestionRadioSelect.vue';
import QuestionCheckbox from './questions/QuestionCheckbox.vue';
import QuestionTextarea from './questions/QuestionTextarea.vue';
import SectionsNav from './SectionsNav.vue';
import Paginator from 'primevue/paginator';

defineEmits(['patch-answer', 'submit-workshop'])

let props = defineProps({
	workshop: Object,
    workshopState: Object,
    answers: Array,
	assessmentId: String
})

const section = ref(props.workshop.sections[0])
const sectionId = ref(section.value.id)
const survey = ref(section.value.surveys[0])

const paginator = computed(() => {
    const totalRecords = props.workshop.sections.reduce((acc, section) => {
        return acc + section.surveys.length;
    }, 0);
    return { rows: 1, totalRecords, current: 0};
});
const onPageChange = (event) => {
    let index = 0;
    for (const i of props.workshop.sections) {
        for (const j of i.surveys) {
            if (index === event.page) {
                section.value = i;
                survey.value = j;
                sectionId.value = i.id;                
                return;
            }
            index++;
        }
    }
};

const changeSection = (newSectionId) => {
    let index = 0;
    for (const i of props.workshop.sections) {
        for (const j of i.surveys) {
            if (i.id === newSectionId) {
                section.value = i;
                survey.value = j;
                sectionId.value = i.id;
                paginator.value.current = index;
                return;
            }
            index++;
        }
    }
};

</script>
<style>
.p-card .p-card-content {
    padding: 0;
}
</style>