import axios from "axios";

const getWorkshopState = (workshopId, token, locale = "EN-CA") => {
  const request = axios.get(
    `/bff/state/workshop/${workshopId}`, {
      headers: {
        Authorization: "Bearer " + token,
        "Accept-Language": locale,
      },
    }
  );
  
  return request.then((res) => res.data);
};

const getWorkshopStatesCompleted = (assessmentId, token, locale = "EN-CA") => {
  const request = axios.get(
    `/bff/state/workshops/completed`, {
      headers: {
        Authorization: "Bearer " + token,
        "Accept-Language": locale,
      },
      params: {
        assessmentId: assessmentId
      }
    }
  );

  return request.then((res) => res.data);
}

const getWorkshopStates = (assessmentId, token, locale = "EN-CA") => {
  const request = axios.get(
    `/bff/state/workshops`, {
      headers: {
        Authorization: "Bearer " + token,
        "Accept-Language": locale,
      },
      params: {
        assessmentId: assessmentId
      }
    }
  );
  
  return request.then((res) => res.data);
}

const submitWorkshopState = (workshopId, body, token, locale = "EN-CA") => {
  const request = axios.patch(
    `/bff/state/workshop/${workshopId}`,body, {
      headers: {
        Authorization: "Bearer " + token,
        "Accept-Language": locale,
      },
    }
  );
  
  return request.then((res) => res.data);
};

export default {
  getWorkshopState,
  getWorkshopStatesCompleted,
  getWorkshopStates,
  submitWorkshopState
};
