<template>
    <div class="mt-4">
        <div class="flex flex-wrap gap-2">
            <template v-for="(val, i) of sections" :key="i" >
                <Card 
                    class="flex-1" 
                    :style="{ backgroundColor: `${backgroundColor[i]}`, color: '#ffffff' }" 
                    :class="{'active-section': currentSectionId === val.id, 'clickable-section': true}"
                    @click="$emit('change-section', val.id)" >
                    <template #content>
                        <div class="flex justify-content-between gap-5" :set="sectionState = getState(val.id)">
                            <div class="flex flex-column gap-1">
                                <span class="font-bold text-lg">{{ val.label }}</span>                                
                            </div>
                            <Button icon="pi pi-check" v-if="sectionState.progression==100" text raised rounded severity="secondary" class="p-button-sm" />          
                        </div>
                    </template>
                </Card>
            </template>
        </div>
    </div>
</template>

<script setup>
import {defineEmits, defineProps} from 'vue';

defineEmits(['change-section'])

let props = defineProps({
	sections: Object,
    sectionStates: Array,
	currentSectionId: String
})

let backgroundColor = [
    'rgb(255, 99, 132)',
    'rgb(75, 192, 192)',
    'rgb(255, 205, 86)',
    'rgb(201, 203, 207)',
    'rgb(54, 162, 235)',
    'rgb(153, 102, 255)',
    'rgb(255, 159, 64)',
    'rgb(255, 77, 166)',
    'rgb(34, 170, 153)',
    'rgb(255, 129, 30)',
    'rgb(120, 90, 40)',
    'rgb(200, 200, 60)',
];

const getState = (sectionId) => {
    if(props.sectionStates) {
        for(let i = 0; i < props.sectionStates.length; i++) {
            if(props.sectionStates[i].sectionId == sectionId) {
                return props.sectionStates[i];
            }
        }
    }
    return {"sectionId": sectionId, "progression": 0}
}
</script>

<style scoped>
.active-section, .clickable-section:hover {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.8);
}
.p-card {
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.3s;
}
</style>