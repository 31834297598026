module.exports = {
    primaryColors: [
        {
            "border": "#9C88FF",
            "background": "rgba(156, 136, 255, 0.1)",
            "point": "#9C88FF"
        },
        {
            "border": "#66CCFF",
            "background": "rgba(102, 204, 255, 0.1)",
            "point": "#66CCFF"
        },
        {
            "border": "#FF8040",
            "background": "rgba(255, 128, 64, 0.1)",
            "point": "#FF8040"
        },
        {
            "border": "#33FF99",
            "background": "rgba(51, 255, 153, 0.1)",
            "point": "#33FF99"
        },
        {
            "border": "#FF66CC",
            "background": "rgba(255, 102, 204, 0.1)",
            "point": "#FF66CC"
        },
        {
            "border": "#48D1CC",
            "background": "rgba(72, 209, 204, 0.1)",
            "point": "#48D1CC"
        },
        {
            "border": "#FFB45E",
            "background": "rgba(255, 180, 94, 0.1)",
            "point": "#FFB45E"
        },
        {
            "border": "#A48BF2",
            "background": "rgba(164, 139, 242, 0.1)",
            "point": "#A48BF2"
        },
        {
            "border": "#4AFF78",
            "background": "rgba(74, 255, 120, 0.1)",
            "point": "#4AFF78"
        },
        {
            "border": "#FF80C0",
            "background": "rgba(255, 128, 192, 0.1)",
            "point": "#FF80C0"
        },
        {
            "border": "#FFA841",
            "background": "rgba(255, 168, 65, 0.1)",
            "point": "#FFA841"
        },
        {
            "border": "#8ACCf0",
            "background": "rgba(138, 204, 240, 0.1)",
            "point": "#8ACCf0"
        },
        {
            "border": "#42E699",
            "background": "rgba(66, 230, 153, 0.1)",
            "point": "#42E699"
        },
        {
            "border": "#FF66CC",
            "background": "rgba(255, 102, 204, 0.1)",
            "point": "#FF66CC"
        },
        {
            "border": "#B380FF",
            "background": "rgba(179, 128, 255, 0.1)",
            "point": "#B380FF"
        },
        {
            "border": "#FF8040",
            "background": "rgba(255, 128, 64, 0.1)",
            "point": "#FF8040"
        },
        {
            "border": "#64D1F4",
            "background": "rgba(100, 209, 244, 0.1)",
            "point": "#64D1F4"
        },
        {
            "border": "#CC66FF",
            "background": "rgba(204, 102, 255, 0.1)",
            "point": "#CC66FF"
        },
        {
            "border": "#48FF82",
            "background": "rgba(72, 255, 130, 0.1)",
            "point": "#48FF82"
        },
        {
            "border": "#FF8AD1",
            "background": "rgba(255, 138, 209, 0.1)",
            "point": "#FF8AD1"
        },
        {
            "border": "#FF5050",
            "background": "rgba(255, 80, 80, 0.1)",
            "point": "#FF5050"
        },
        {
            "border": "#70CCFF",
            "background": "rgba(112, 204, 255, 0.1)",
            "point": "#70CCFF"
        },
        {
            "border": "#FFB35E",
            "background": "rgba(255, 179, 94, 0.1)",
            "point": "#FFB35E"
        },
        {
            "border": "#33FF99",
            "background": "rgba(51, 255, 153, 0.1)",
            "point": "#33FF99"
        },
        {
            "border": "#FF8AD1",
            "background": "rgba(255, 138, 209, 0.1)",
            "point": "#FF8AD1"
        }
    ],
    gridColor: "#b7bec7",
    axisColor:"#8b939e",
    textColor: "#000000",
}