<template>
    <div ref="root">
        <DataTable :value="results" stripedRows tableStyle="width: 100%">
            <Column field="name" :header="$t('result.question.name')"></Column>
            <Column field="team" :sortable="true" :header="$t('result.question.team')"></Column>
            <Column field="score" style="width:15%"  :sortable="true" :header="$t('result.question.value')"></Column>
            <Column field="comment" :sortable="true" :header="$t('result.question.comment')"></Column>
            <Column field="workshopCompleted" :sortable="true" :header="$t('result.question.workshopCompleted')" dataType="boolean" class="text-center"   style="width:15%">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.isWorkshopCompleted, 'pi-times-circle text-red-400': !data.isWorkshopCompleted }"></i>
                </template>
            </Column>
        </DataTable>

        <div class="h-3rem"></div>
        {{ $t("result.comments") }}
        <div class="h-2rem"></div>
        <Textarea v-model="localAnalysis" class="w-full" :rows="5" :autoResize="true" @change="event => updateSurveyAnalysis(event.target.value)" :disabled="!canEdit" />
    </div>
</template>

<script setup>
import { ref, defineProps, watch, onMounted } from "vue";
import BffAnalysisService from "../../../services/BffAnalysisService";
import useAuth from "@/utils/auth";
import $ from 'jquery'

const { getToken } = useAuth()

const setAsideCommentsHidden = ref(false)
const root = ref(null)
const localAnalysis = ref(props.analysis);

watch(() => props.analysis, (newVal) => {
    localAnalysis.value = newVal;
});

let props = defineProps({
    sectionId: String,
    surveyId: String,
    analysis: String,
    results: Array,
    assessmentId: String,
    workshopId: String,
    canEdit: Boolean
})

onMounted (() => {
    changeSetAsideCommentsVisibility()
});

// const getInitialSettings = () => {
//     let settings = {
//         relevantList: [],
//         setAsideList: []
//     };

//     return settings;
// }

// const initPickListData = () => {
//     let relevantList = []
//     let setAsideList = []

//     // Check if there is a participant that is not in the list
//     props.results.forEach(p=>{
//         // if (relevantList.filter(pl=>pl.id == p.id).length == 0 && setAsideList.filter(pl=>pl.id == p.id).length == 0) {
//         relevantList.push(p)
//         // }
//     })

//     return [relevantList, setAsideList]
// }

// const populatePickList = (settingsList, tableList) => {
//     // settingsList.forEach(id=>{
//     //     // let participation = props.assessment.participations.find(p=>p.id == id)
//     //     // if (participation != null) {
//     //     //     tableList.push(getParticipationForPickList(participation))
//     //     // }
//     // })
// }

const updateSurveyAnalysis = (value) => {
    getToken().then(token=>{
        BffAnalysisService.updateSurveyEvaluationAnalysis(
            props.assessmentId,
            {
                analysis: value,
                surveyId: props.surveyId,
                sectionId: props.sectionId,
                workshopId: props.workshopId
            },
            token
    )})
}

const changeSetAsideCommentsVisibility = () => {
    setAsideCommentsHidden.value = !setAsideCommentsHidden.value
    $(root.value).find(".p-picklist-list-wrapper.p-picklist-target-wrapper").toggleClass("invisible")
    $(root.value).find(".p-picklist-buttons.p-picklist-target-controls").toggleClass("invisible")
    $(root.value).find(".p-picklist-buttons.p-picklist-transfer-buttons").find("button:nth-child(3)").toggleClass("invisible")
    $(root.value).find(".p-picklist-buttons.p-picklist-transfer-buttons").find("button:nth-child(4)").toggleClass("invisible")
}
</script>
