<template>
    <template v-if="ScenariosResults != null">
        <template v-for="(result) in ScenariosResults" :key="result.scenarioId">
            <Fieldset :legend="result.text" :toggleable="true" :collapsed="true">
                <Scenario
                    :assessmentId="assessmentId"
                    :workshopId="workshop.id"
                    :visualisationScenarioAnalyses="result.visualisationScenarioAnalysisResources"
                    :participationsScenario="result.participationResults"
                    :canEdit="canEdit"
                />
            </Fieldset>
        </template>
    </template>
</template>
<script setup>
import { defineProps, ref, onBeforeMount } from "vue";
import BffResultService from '@/services/BffResultService';
import Scenario from './Scenario.vue';
import useAuth from "@/utils/auth";

const { getToken } = useAuth()

const ScenariosResults = ref({})


let props = defineProps({
    assessmentId: Object,
    workshop: Object,
    canEdit: Boolean
})

onBeforeMount (() => {
    getScenarioScenarioResult()
})

const getScenarioScenarioResult = () => {
    getToken().then(token=>{
        BffResultService.getVisualisationScenarioResult(
            props.workshop.id,
            props.assessmentId,
            token
        ).then(p=> {
            ScenariosResults.value = p;
    })});
}
</script>