<template>
    <!-- Do not remove this div because it suppress warning -->
    <div class="col-12">
        <Single
            v-if="showSingleWorkshop()"
            :assessmentId="assessmentId"
            :workshop="workshop"
            :canEdit="canEdit"
        />
        <SingleSection
            v-if="showSingleSectionWorkshop()"
            :assessmentId="assessmentId"
            :workshop="workshop"
            :canEdit="canEdit"
        />
        <MultipleSections
            v-if="showMultipleSectionsWorkshop()"
            :assessmentId="assessmentId"
            :workshop="workshop"
            :canEdit="canEdit"
        />
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { useRoute } from 'vue-router'
import WorkshopService from '@/services/WorkshopService';
import BffWorkshopService from '@/services/BffWorkshopService';
import MultipleSections from '../components/result/multipleSections/MultipleSections.vue';
import SingleSection from '../components/result/singleSection/SingleSection.vue';
import Single from '../components/result/single/Single.vue';
import useAuth from "@/utils/auth";
import {useI18n} from 'vue-i18n';

const {locale} = useI18n();
const { getToken } = useAuth()
const assessmentId = ref(useRoute().params.assessmentId)
const workshopId = ref(useRoute().params.id)
const workshop = ref({})
const canEdit = ref()

onBeforeMount (() => {
    loadWorkshop()
})

const loadWorkshop = () => {
   getToken().then(token =>
        BffWorkshopService.getWorkshop(workshopId.value, token, locale.value).then(w => {
        canEdit.value = w.acl.accesses.read
        workshop.value = w
    }));
}

const dataLoaded = () => {
    return workshop.value.id;
}

const showMultipleSectionsWorkshop = ()  => {
    return dataLoaded() && WorkshopService.isMultipleSectionsWorkshop(workshop.value)
}

const showSingleSectionWorkshop = ()  => {
    return dataLoaded() && WorkshopService.isSingleSectionWorkshop(workshop.value)
}

const showSingleWorkshop = ()  => {
    return workshop.value.id && WorkshopService.isSingleWorkshop(workshop.value)
}
</script>