<template>
    <template v-if="assessmentId">
        <h1>{{ workshop.title }}</h1>
        <div class="h-1rem"></div>
        <TabView>
            <TabPanel v-for="section in workshop.sections" :header="section.label" :key="section.id">
                    <div class="h-1rem"></div>
                    <h3>{{section.title}}</h3>  
                    <div class="h-4rem"></div>
                    
                    <ResultChart
                        v-if="chartDataPerSection"
                        :sectionData="chartDataPerSection.find(s => s.sectionId === section.id)"
                        :sectionId="section.id"
                        :assessment="assessment"
                        :workshopId="workshop.id"
                    />

                    <div class="h-4rem"></div>
                    {{ $t("result.analysis") }}
                    <div class="h-1rem"></div>
                    <Textarea :value="getAnalysisForSection(section.id).value" class="w-full" :rows="5" :autoResize="true" :disabled="!canEdit" @change="event => updateSectionAnalysis(section.id, event.target.value)" />
                    <template v-for="survey in section.surveys" :key="survey.id">
                        <div class="h-3rem"></div>
                        <Fieldset :legend="survey.title" :toggleable="true" :collapsed="true">
                                <ResultText
                                    :sectionId="section.id"
                                    :surveyId="survey.id"
                                    :workshopId="workshop.id"
                                    :analysis="getAnalysisForSectionAndSurvey(section.id, survey.id)"
                                    :results="participantResult.filter(result => result.sectionId === section.id && result.surveyId === survey.id)"
                                    :assessmentId="assessmentId"
                                    :canEdit="canEdit"
                                />                                       
                        </Fieldset>
                    </template>
            </TabPanel>
        </TabView>
    </template>
</template>

<script setup>
import { defineProps, ref, onBeforeMount, computed } from "vue";
import ResultText from './ResultText.vue';
import ResultChart from './ResultChart.vue';
import BffResultService from "../../../services/BffResultService";
import BffAnalysisService from "../../../services/BffAnalysisService";
import useAuth from "@/utils/auth";

const { getToken } = useAuth()

const analyses = ref([])
const chartDataPerSection = ref([])
const participantResult = ref([])

import {useI18n} from 'vue-i18n';
const {locale} = useI18n();

let props = defineProps({
    assessmentId: String,
    workshop: Object,
    canEdit: Boolean,
})

onBeforeMount (() => {
    getEvaluationResult();
})

const getAnalysisForSection = (sectionId) => {
    return computed(() => analyses.value.find(analysis => analysis.sectionId === sectionId)?.analysis || "");
};

const getAnalysisForSectionAndSurvey = (sectionId, surveyId) => {
    return computed(() => {
        const sectionAnalysis = analyses.value.find(analysis => analysis.sectionId === sectionId);
        
        if (!sectionAnalysis){
            return "";
        }

        return sectionAnalysis.evaluationSurveyAnalyses.find(surveyAnalysis => surveyAnalysis.surveyId === surveyId)?.analysis || "";
    });
};

const getEvaluationResult = () => {
    getToken().then(token =>  
        BffResultService.getEvaluationResult(props.workshop.id,props.assessmentId,token,locale).then(result => {
            chartDataPerSection.value = result.chartResource.section;
            analyses.value = result.evaluationSectionAnalysisResources;
            participantResult.value = result.evaluationParticipantResultResources;
            }));      
}

const updateSectionAnalysis = (sectionId, value) => {
    const analysis = {sectionId: sectionId, analysis: value, workshopId: props.workshop.id};

    getToken().then(token=>{
        BffAnalysisService.updateSectionEvaluationAnalysis(
            props.assessmentId,
            analysis,
            token
    )});
}
</script>