<template>
 <div>
    <Calendar  :showIcon="true"  >
     <template #date="slotProps"> 
        <strong v-if="slotProps.date.day >= start.getDate()  && slotProps.date.day <= (end.getDate() + 1) && slotProps.date.month == start.getMonth() && start.getMonth() == end.getMonth() && slotProps.date.year == start.getFullYear()" class="special-day">{{slotProps.date.day}}</strong>

        <strong v-else-if="slotProps.date.day >= start.getDate() && slotProps.date.month >= start.getMonth() && slotProps.date.month < end.getMonth() &&  slotProps.date.year == start.getFullYear()" class="special-day">{{slotProps.date.day}}</strong>


      <!-- <strong v-else-if="slotProps.date.month > start.getMonth() && slotProps.date.month != end.getMonth() &&  slotProps.date.year == start.getFullYear()" class="special-day">{{slotProps.date.day}}</strong> -->

      <strong v-else-if="slotProps.date.day <= (end.getDate() + 1)  && slotProps.date.month != start.getMonth() && slotProps.date.month == end.getMonth() &&  slotProps.date.year == start.getFullYear()" class="special-day">{{slotProps.date.day}}</strong>

      <template v-else ><div class="no-day">{{slotProps.date.day}}</div></template>
    </template>
    </Calendar>
</div>
</template>



<script setup>
import {defineProps, ref} from 'vue'

const props = defineProps({
  "startDate" : new Date(),
  "endDate" : new Date()
})

let start = ref(props.startDate)
let end = ref(props.endDate)


</script>

<style > 
Calendar input.p-inputtext{
  display:none
}

.special-day {
    /* text-decoration: line-through; */
    color: green
}

.no-day {
  text-decoration: line-through;
}
 </style>