<template v-if="assessmentId != null">
    <span class="text-7xl text-color-secondary">{{ $t("report.title") }}</span>

    <div
        class="col-12"
        v-for="template of templates"
        :key="template.id">

        <Fieldset
            :legend="template.label"
            :toggleable="true"
            :collapsed="true"
        >
            <Single
                v-if="BffWorkshopService.isSingleWorkshop(template)"
                :assessmentId="assessmentId"
                :workshop="template"
                :canEdit="false"
            />
            <SingleSection
                v-if="BffWorkshopService.isSingleSectionWorkshop(template)"
                :assessmentId="assessmentId"
                :workshop="template"
                :canEdit="false"
            />
            <MultipleSections
                v-if="BffWorkshopService.isMultipleSectionsWorkshop(template)"
                :assessmentId="assessmentId"
                :workshop="template"
                :canEdit="false"
            />
        </Fieldset>
    </div>

    <div class="col-12">
        <Fieldset
            :legend="$t('recommendation.recommendations')"
            :toggleable="true"
            :collapsed="true"
            >

            <Recommendations
                :assessmentId="assessmentId"
            />
        </Fieldset>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, defineProps } from "vue";

import MultipleSections from '../components/result/multipleSections/MultipleSections.vue';
import SingleSection from '../components/result/singleSection/SingleSection.vue';
import Single from '../components/result/single/Single.vue';
import Recommendations from '../components/recommendation/Recommendations.vue';
import BffWorkshopService from "@/services/BffWorkshopService"
import {useI18n} from 'vue-i18n';
import useAuth from "@/utils/auth";

const { getToken } = useAuth()
const { locale } = useI18n({ useScope: 'global' })

const templates = ref(null)

let props = defineProps({
    assessmentId: String
})

onBeforeMount (() => {
    loadWorkshopTemplates()
})

const loadWorkshopTemplates = () => {
    getToken().then(token => BffWorkshopService.getWorkshopTemplatesFromAssessment(props.assessmentId, token, locale)
    .then(wl => {
        templates.value = wl
    }))
}
</script>