import axios from "axios";

const getRecommendations = async (assessmentId, token, locale = "EN-CA") => {

    const request = axios.get(`/bff/recommendation`, {
        headers: {
            Authorization: "Bearer " + token,
            "Accept-Language": locale,
        },
        params: {
          assessmentId: assessmentId
        }
    })

  return request.then((res) => res.data)
}

const moveRecommendation = async (id, toIndex, order, token, locale = "EN-CA") => {

    const request = axios.get(`/bff/recommendation/${id}/sequence`, {
        headers: {
            Authorization: "Bearer " + token,
            "Accept-Language": locale,
        },
        params: {
          toIndex: toIndex,
          order: order
        }
    })

  return request.then((res) => res.data)
}

const deleteRecommendations = async (ids, token, locale = "EN-CA") => {

    const request = axios.delete(`/bff/recommendation`, {
        headers: {
            Authorization: "Bearer " + token,
            "Accept-Language": locale,
        },
        params: {
          ids: ids,
        },
        paramsSerializer: {
        indexes: null
        }
    })

  return request.then((res) => res.data)
}

const createRecommendation = async (assessmentId, recommendation, token, locale = "EN-CA") => {

    const request = axios.post(`/bff/recommendation`, recommendation, {
        headers: {
            Authorization: "Bearer " + token,
            "Accept-Language": locale,
        },
         params: {
           assessmentId: assessmentId,
         }
    })

  return request.then((res) => res.data)
}

const updateRecommendation = async (recommendation, token, locale = "EN-CA") => {

    const request = axios.patch(`/bff/recommendation/` + recommendation.id, recommendation, {
        headers: {
            Authorization: "Bearer " + token,
            "Accept-Language": locale,
        }
    })

  return request.then((res) => res.data)
}

export default {
  getRecommendations,
  deleteRecommendations,
  createRecommendation,
  updateRecommendation,
  moveRecommendation
}
