<template>
    <template v-if="assessment.id && workshop.id">
        <WorkshopSingle
            v-if="!questionId && BffWorkshopService.isSingleWorkshop(workshop)"
            :assessmentId="assessment.id"
            :workshop="workshop"
            :workshopState="workshopState"
            :answers="answers"
            @patch-answer="patchAnswer"
            @submit-workshop="submitWorkshop"
        />

        <SectionSingle
            v-if="!questionId && BffWorkshopService.isSingleSectionWorkshop(workshop)"
            :assessmentId="assessment.id"
            :workshop="workshop"
            :workshopState="workshopState"
            :answers="answers"
            @patch-answer="patchAnswer"
            @submit-workshop="submitWorkshop"
        />

        <SectionMultiple
            v-if="BffWorkshopService.isMultipleSectionsWorkshop(workshop)"
            :assessmentId="assessment.id"
            :workshop="workshop"
            :workshopState="workshopState"
            :answers="answers"
            @patch-answer="patchAnswer"
            @submit-workshop="submitWorkshop"
        />
    </template>
</template>

<script setup>
import useAuth from "@/utils/auth";
import {defineProps, onBeforeMount, ref} from 'vue';
import BffWorkshopService from '@/services/BffWorkshopService';
import WorkshopSingle from '../components/workshop/WorkshopSingle.vue';
import SectionSingle from '../components/workshop/SectionSingle.vue';
import SectionMultiple from '../components/workshop/SectionMultiple.vue';
import BffWorkshopStateService from "../services/BffWorkshopStateService";
import BffAnswerService from '@/services/BffAnswerService';


import {useI18n} from 'vue-i18n';
import "../assets/styles/dashboardWorkshop.scss";
import { useRoute } from 'vue-router'

const {locale} = useI18n();
const { getToken } = useAuth();
const workshop = ref({id: null});
const answers = ref([]);
const workshopId = ref(useRoute().params.id);
const questionId = ref(useRoute().query.questionId);
const workshopState = ref({});

defineProps({
    assessment: Object
});

onBeforeMount (() => {
    loadAnswersFromWorkshop();
    loadWorkshopState();
    loadWorkshop();
})

const loadFromWorkshop = (w) => {
    let tmpSections;
    let firstLoad = true;

    if (workshop.value.id != null) {
        tmpSections = workshop.value.sections
        tmpSections.splice(0)
        workshop.value.sections.push(...w.sections)
        firstLoad = false
    }

    workshop.value = w

    if (!firstLoad) {
        workshop.value.sections = tmpSections
    }
}

const loadWorkshop = () => {
    getToken().then(token =>
        BffWorkshopService.getWorkshop(workshopId.value, token, locale.value).then(w =>{
            loadFromWorkshop(w)
    }))
}

const loadWorkshopState = () => {
    getToken().then(token => BffWorkshopStateService.getWorkshopState(workshopId.value, token, locale.value).then(ws => {
        workshopState.value = ws
    }))
}

const loadAnswersFromWorkshop = () => {
    getToken().then(token =>
        BffAnswerService.getAnswersFromWorkshop(workshopId.value, token, locale.value).then(ans =>{
            answers.value = ans;
    }))
}

const submitWorkshop = () => {
    getToken().then(token =>
        BffWorkshopStateService.submitWorkshopState(workshop.value.id,
        { id: workshopState.value.id, workshopId: workshopState.value.workshopId} , token).then(() =>
        loadWorkshopState())
    )
}

function patchAnswer(body, answerId) {
    getToken().then(token => {
        BffAnswerService.updateAnswer(answerId, body, token)
    .then( () => {
        loadWorkshopState();
    })});
}
</script>
