<template>
    <div class="justify-content-center">
        <Chart id="canvas" class="radar" type="radar" :data="chartData" :options="chartOptions" />
    </div>
</template>

<script setup>
import { ref, defineProps} from 'vue';
import "../../../assets/styles/result/chart.scss"
import {primaryColors,gridColor,textColor, axisColor} from "../../../assets/colors"
let props = defineProps({
    sectionData: Object
})

let datasets = ref(props.sectionData.datasets);

let listDatasets = [];
let listColors = primaryColors

for (let i = 0; i< datasets.value.length;i++) {
    let data = {
            label: datasets.value[i].label,
            backgroundColor: listColors[i].background,
            borderColor: listColors[i].border,
            pointBackgroundColor: listColors[i].point,
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: `rgba(179,181,198,1)`,
            data: datasets.value[i].data
        }
        listDatasets.push(data)
    }


const chartData = ref({
    labels: props.sectionData.labels,
    datasets: listDatasets,
});

const chartOptions = ref({
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            labels: {
                color: textColor,
                font:{
                    size: 14
                }
            },
        },
        decimation: true,
    },

    scales: {
        r: {
            pointLabels: {
                color: textColor,
                font:{
                    size: 12
                }
            },
            grid: {
                color: gridColor,
            },
            angleLines: {
                color: axisColor,
            },
            min: 0,
            max: 5,
            ticks: {
                maxTicksLimit: 6,
                display: false
            }    
            },      
        }
});
</script>