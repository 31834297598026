<template>
    <h5 v-html="question.title"></h5>
    <div v-html="question.body"></div>
    <br/>
    
    <div v-for="choice of question.choices" :key="choice.id" class="field-radiobutton">
            <RadioButton
                :disabled="readOnly"
                :value="choice.id"
                v-model="questionValue[0]"
                @change="$emit('patch-answer', {content: questionValue})" />

            <label :for="choice.id">{{choice.text}}</label>

    </div>
</template>

<script setup>

import { ref, defineProps,defineEmits } from 'vue'

defineEmits(["patch-answer"]);

let props = defineProps({
	question: Object,
    answer: Object,
	readOnly: Boolean
})

let questionValue = ref(props.answer.content ?? "")
</script>