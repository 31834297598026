<template>

    <p v-html="question.body"></p>

    <Textarea
        v-model="questionValue[0]"
        :disabled="readOnly"
        :autoResize="true"
        @change="$emit('patch-answer', {content: questionValue})"
        rows="6"
        class="w-full" />
    <br /><br />

</template>

<script setup>
import { ref, defineProps,defineEmits } from 'vue'
defineEmits(["patch-answer"]);


let props = defineProps({
	question: Object,
    answer: Object,
	readOnly: Boolean
})

let questionValue = ref(props.answer.content ?? "");
</script>