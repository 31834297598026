import { useAuth0 } from "@auth0/auth0-vue";
import getEnv from "@/utils/env";

export default function useAuth() {

    const { getAccessTokenSilently } = useAuth0()
    const {user} = useAuth0()

    async function getToken() {
        const token = await getAccessTokenSilently()
        return token
    }

    function getLoggedUserRole() {
        return user.value[getEnv("VUE_APP_AUTH0_AUDIENCE") + "/roles"][0]
    }

    function isUserRoleAdmin() {
        return getLoggedUserRole() == 'godevops-admin'
    }

    function isUserRoleCompanyAdmin() {
        return getLoggedUserRole() == 'godevops-company-admin'
    }

    function isUserRoleAssessmentOwner() {
        return getLoggedUserRole() == 'godevops-assessment-owner'
    }

    function isUserRoleParticipant() {
        return getLoggedUserRole() == 'godevops-participant'
    }

    return {
        getToken,
        getLoggedUserRole,
        isUserRoleAdmin,
        isUserRoleCompanyAdmin,
        isUserRoleAssessmentOwner,
        isUserRoleParticipant
    }
}

