<template>
    <div class="surface-0 flex justify-content-center">
        <div id="home" class="landing-wrapper overflow-hidden">
            <div
                class="py-4 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 flex align-items-center justify-content-between relative lg:static">
                <a class="flex align-items-center" href="#">
                    <router-link to="/" class="flex align-items-center">
                        <img src="layout/images/logo.png" alt="GodevOps Logo" height="50" class="mr-0 lg:mr-2">
                    </router-link>                    
                </a>
                <a class="cursor-pointer block lg:hidden text-700 p-ripple" v-ripple
                    v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                    <i class="pi pi-bars text-4xl"></i>
                </a>
                <div class="align-items-center surface-0 flex-grow-1 justify-content-end lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2"
                    style="top: 120px">
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
                        <li>
                            <a href="https://www.gologic.ca/a-propos-de-gologic/" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                                <span>À propos</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.gologic.ca/#contact" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                                <span>Contact</span>
                            </a>
                        </li>
                    </ul>
                    <div
                        class="flex justify-content-between lg:flex border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
                        <Button @click="login" class="p-button-rounded border-none ml-5 font-light text-white line-height-2 bg-blue-500"><b>{{ $t("home.login") }}</b></Button>
                    </div>
                </div>
            </div>

            <div id="hero" class="flex flex-row pt-4 px-4 lg:px-8 overflow-hidden"
                style="background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #C1DB99 5%, #80D2E4 80%)">
                <div class="flex w-full">
                    <div class="col-5 ml-2 md:ml-2 mt-0 md:mt-2">
                        <h1 class="text-6xl font-bold line-height-2 primary-text-color"><span class="font-light block">{{ $t("home.main_text_1") }}</span>{{ $t("home.main_text_2") }}</h1>
                        <p v-html="$t('home.main_text_3')" class="font-normal md:mt-3 text-gray-700"></p>
                        <Button @click="login" class="p-button-rounded text-xl border-none mt-5 font-normal text-white line-height-3 px-3"><b>{{ $t("home.start") }}</b></Button>
                    </div>
                    <div >
                        <img  src="layout/images/homepage.png"  class="w-9 md:w-auto" alt="hero screen">
                    </div>
                </div>
            </div>

            <div id="features" class="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8">
   <div class="grid justify-content-center">
      <div class="col-12 text-center mt-8 mb-4">
         <h2 class="text-900 font-normal mb-2">Les fonctionnalités de GoDevOps</h2>
         <span class="text-600 text-2xl">Une plateforme d'amélioration continue...</span>
      </div>
      <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
         <div style="height: 160px; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(253, 228, 165, 0.2),rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2),rgba(187, 199, 205, 0.2));">
            <div class="p-3 surface-card h-full" style="border-radius: 8px;">
               <div class="flex align-items-center justify-content-center bg-yellow-200 mb-3" style="width: 3.5rem; height: 3.5rem; border-radius: 10px;"><i class="pi pi-fw pi-users text-2xl text-yellow-700"></i></div>
               <h5 class="mb-2 text-900">Phase de découverte</h5>
               <span class="text-600">Fixer les priorités, les résultats souhaités et la portée</span>
            </div>
         </div>
      </div>
      <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
         <div style="height: 160px; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(145,226,237,0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(172, 180, 223, 0.2));">
            <div class="p-3 surface-card h-full" style="border-radius: 8px;">
               <div class="flex align-items-center justify-content-center bg-cyan-200 mb-3" style="width: 3.5rem; height: 3.5rem; border-radius: 10px;"><i class="pi pi-fw pi-palette text-2xl text-cyan-700"></i></div>
               <h5 class="mb-2 text-900">Analyse de maturité</h5>
               <span class="text-600">Évaluer et analyser les équipes face au marché</span>
            </div>
         </div>
      </div>
      <div class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
         <div style="height: 160px; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(145, 226, 237, 0.2), rgba(172, 180, 223, 0.2)), linear-gradient(180deg, rgba(172, 180, 223, 0.2), rgba(246, 158, 188, 0.2));">
            <div class="p-3 surface-card h-full" style="border-radius: 8px;">
               <div class="flex align-items-center justify-content-center bg-indigo-200" style="width: 3.5rem; height: 3.5rem; border-radius: 10px;"><i class="pi pi-fw pi-map text-2xl text-indigo-700"></i></div>
               <h5 class="mb-2 text-900">Analyse de processus</h5>
               <span class="text-600">Discuter du processus de développement et de livraison avec les différentes parties prenantes</span>
            </div>
         </div>
      </div>
      <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
         <div style="height: 160px; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(187, 199, 205, 0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2),rgba(145, 210, 204, 0.2));">
            <div class="p-3 surface-card h-full" style="border-radius: 8px;">
               <div class="flex align-items-center justify-content-center bg-bluegray-200 mb-3" style="width: 3.5rem; height: 3.5rem; border-radius: 10px;"><i class="pi pi-fw pi-id-card text-2xl text-bluegray-700"></i></div>
               <h5 class="mb-2 text-900">Synthèse</h5>
               <span class="text-600">Émettre des constats sur les résultats des ateliers</span>
            </div>
         </div>
      </div>
      <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
         <div style="height: 160px; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(187, 199, 205, 0.2),rgba(246, 158, 188, 0.2)), linear-gradient(180deg, rgba(145, 226, 237, 0.2),rgba(160, 210, 250, 0.2));">
            <div class="p-3 surface-card h-full" style="border-radius: 8px;">
               <div class="flex align-items-center justify-content-center bg-orange-200 mb-3" style="width: 3.5rem; height: 3.5rem; border-radius: 10px;"><i class="pi pi-fw pi-star text-2xl text-orange-700"></i></div>
               <h5 class="mb-2 text-900">Recommandations</h5>
               <span class="text-600">Développer et proposer des recommandations en lien avec les objectifs attendues et les enjeux vécus.</span>
            </div>
         </div>
      </div>
      <div class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
         <div style="height: 160px; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(251, 199, 145, 0.2), rgba(246, 158, 188, 0.2)), linear-gradient(180deg, rgba(172, 180, 223, 0.2), rgba(212, 162, 221, 0.2));">
            <div class="p-3 surface-card h-full" style="border-radius: 8px;">
               <div class="flex align-items-center justify-content-center bg-pink-200 mb-3" style="width: 3.5rem; height: 3.5rem; border-radius: 10px;"><i class="pi pi-fw pi-moon text-2xl text-pink-700"></i></div>
               <h5 class="mb-2 text-900">Feuille de route</h5>
               <span class="text-600">Définir et prioriser un backlog pour s'améliorer en continu à court, moyen et long terme.</span>
            </div>
         </div>
      </div>
   </div>
</div>
        </div>


        
    </div>

</template>

<style scoped>
@media screen and (min-width: 768px) {
    #hero {
        -webkit-clip-path: ellipse(150% 87% at 93% 13%);
        clip-path: ellipse(150% 87% at 93% 13%);
    }
}

@media screen and (min-width: 1300px) {
    #hero>img {
        position: absolute;
    }

    #hero>div>p {
        max-width: 450px;
    }
}

@media screen and (max-width: 1300px) {
    #hero {
        height: 600px;
    }

    #hero>img {
        position: static;
        transform: scale(1);
        margin-left: auto;
    }

    #hero>div {
        width: 100%;
    }

    #hero>div>p {
        width: 100%;
        max-width: 100%;
    }
}
</style>

<script>
import { useAuth0 } from '@auth0/auth0-vue';
import { computed } from 'vue'
import getEnv from '@/utils/env';

export default {

    setup() {
        const { loginWithRedirect, user, isAuthenticated } = useAuth0();
        const logoColor = computed(() => {
            return 'dark';            
        })


        return {
            login: () => {
                loginWithRedirect(
                    {
    redirect_uri:  getEnv('VUE_APP_ENV_PUBLIC_URL') + "/#/dashboard",
     appState: { target: '/dashboard' }
  });
            },
            user, 
            isAuthenticated,
            smoothScroll: (id) => {
                document.querySelector(id).scrollIntoView({
                    behavior: 'smooth'
                });
            },
            logoColor
        }    
    }
}
</script>

<style>
#hero{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EEEFAF 0%, #C3E3FA 100%);
    height:100%;

}

@media screen and (min-width: 768px) {
    #hero{
        -webkit-clip-path: ellipse(150% 87% at 93% 13%);
        clip-path: ellipse(150% 87% at 93% 13%);
        height: 700px;
    }
}

@media screen and (min-width: 1300px){
    #hero > img {
        position: absolute;
    }

    #hero > div > p { 
        max-width: 450px;
    }
}

@media screen and (max-width: 1300px){
    #hero {
        height: 1000px;
    }

    #hero > img {
        position:static;
        transform: scale(1);
        margin-left: auto;
    }

    #hero > div {
        width: 100%;
    }

    #hero > div > p {
        width: 100%;
        max-width: 100%;
    }
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.surface-0.overflow-hidden {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

#contact {
    position: fixed;
    bottom: 0;
    background-color: var(--surface-ground);
    width: 100%;
    margin-top: auto;
    padding-left: 1rem;
    padding-right:1 re
}
</style>