
<template>
	<div class="layout-footer">
		<img alt="Logo" :src="footerImage()" height="20" class="mr-2" />
		<span class="font-medium ml-2">{{ $t("dashboard.title.footer") }}</span>
		<br />
	</div>
	<div class="layout-footer" style="border:none;">
		{{ $t("common.locale") }} <SelectLocale style="padding-left: 10px;" />
	</div>	
</template>

<script>
	export default {
		name: "AppFooter",
		methods: {
			footerImage() {
				return 'images/logo-dark.svg';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>