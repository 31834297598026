<template>
    <div class="h-3rem"></div>

    <DataTable :value="visualisationScenarioAnalysisResources">
        <Column field="scenarioName" :header="$t('result.scenario')" class="col-2" />
        <Column field="impactLevel" :header="$t('result.level')" class="col-1" />
        <Column field="problem" :header="$t('result.problematic')" class="col-3 "/>
        <Column field="impact" :header="$t('result.impact')" class="col-3" />
        <Column field="improvement" :header="$t('result.improvement')" class="col-3" />
    </DataTable>

    <div class="h-2rem"></div>
    {{ $t("result.impactLevel") }} :   <Dropdown v-model="localVisualisationStepAnalysisResource.impactLevel" :options="impactLevelSelect" optionLabel="impact" optionValue="index" :editable="true" @change="updateAnalysis" :disabled="!canEdit"/>
    <div class="h-2rem"></div>
    {{ $t("result.constraints") }}
    <div class="h-2rem"></div>

    <Textarea v-model="localVisualisationStepAnalysisResource.constraint" class="w-full" :rows="5" :autoResize="true"  :disabled="!canEdit" @change="updateAnalysis" />
</template>

<script setup>
import BffAnalysisService from "../../../services/BffAnalysisService";
import { defineProps, ref, watch, emit} from "vue";
import useAuth from "@/utils/auth";
const { getToken } = useAuth()

let props = defineProps({
    assessmentId: String,
    visualisationStepAnalysisResource: Object,
    visualisationScenarioAnalysisResources: Object,
    canEdit: Boolean
})

const localVisualisationStepAnalysisResource = ref({...props.visualisationStepAnalysisResource});

watch(() => props.visualisationStepAnalysisResource, (newVal) => {
    localVisualisationStepAnalysisResource.value = {...newVal};
});


const updateAnalysis = () => {
    getToken().then(token => {
        BffAnalysisService.updateVisualisationStepAnalysis(props.assessmentId, localVisualisationStepAnalysisResource.value, token).then(() => {
            emit('updateAnalysis', localVisualisationStepAnalysisResource.value);
        });
    });
}; 

const impactLevelSelect = ref([{impact: "Faible", index: 1},
                                {impact: "Moyen", index: 2},
                                {impact: "Élevé", index: 3}])

</script>