/* eslint-disable no-unused-vars*/
import axios from "axios";

const getParticipationByEmail = (token, email) => {
  return getParticipationByEmailPromise(token, email).then((res) => res.data);
};

const getParticipationByEmailPromise = (token, email) => {
  return axios.get("/participation?email=" + email, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export default {
  getParticipationByEmail,
  getParticipationByEmailPromise
};
