<template>
    <div v-html="question.body"></div>
    <br/>

    <div v-for="choice of question.choices" :key="choice.id" class="field-radiobutton">
        <RadioButton
            :disabled="readOnly"
            name="choice"
            :value="choice.id"
            v-model="radioValue"
            @change="$emit('patch-answer', {comment: textValue, content: radioValue})" />

        <label :for="choice.id">{{choice.text}}</label>
    </div>

    <p v-html="question.commentQuestion" class="mt-6"></p>

    <Textarea
        v-model="textValue"
        :disabled="readOnly"
        :autoResize="true"
        @change="$emit('patch-answer', {comment: textValue, content: radioValue})"
        rows="6"
        class="w-full" />
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'

defineEmits(["patch-answer"]);

let props = defineProps({
    question: Object,
    answer: Object,
    readOnly: Boolean
})

let radioValue = ref(props.answer.content ?? "") // Use nullish coalescing operator to provide a default value
let textValue = ref(props.answer.comment ?? "") // Use nullish coalescing operator to provide a default value
</script>
