import axios from "axios";

const getAnswersFromWorkshop = (workshopId, token, locale = "EN-CA") => {
  const request = axios.get(
    `/bff/answer/workshop/${workshopId}`, {
      headers: {
        Authorization: "Bearer " + token,
        "Accept-Language": locale,
      },
    }
  );
  
  return request.then((res) => res.data);
};

const updateAnswer = (id, answer, token, locale = "EN-CA") => {
  const url = `bff/answer/${id}`;
  
  const request = axios.patch(url, answer, {
    headers: {
      Authorization: "Bearer " + token,
      "Accept-Language": locale,
    },
  });

  return request.then((res) => res.data);

};

export default {
  getAnswersFromWorkshop,
  updateAnswer
};
