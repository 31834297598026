import axios from "axios";

const getWorkshopTemplatesFromAssessment = async (
  assessmentId,
  token,
  locale = "EN-CA"
) => {
  const response = await axios.get("bff/workshop/templates", {
    headers: {
      Authorization: "Bearer " + token,
      "Accept-Language": locale,
    },
    params: {
      assessmentId: assessmentId,
    },
  });

  return response.data;
}

const getWorkshop = (
    id,
    token,
    locale = "EN-CA"
) => {
  const request = axios.get(`/bff/workshop/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
      "Accept-Language": locale,
    },
  });

  return request.then((res) => res.data);
};

const getPreparationWorkshop = (
  templateId,
  assessmentId,
  token,
  locale = "EN-CA"
) => {
const request = axios.get(`/bff/workshop/preparation/${templateId}?assessmentId=${assessmentId}`, {
  headers: {
    Authorization: "Bearer " + token,
    "Accept-Language": locale,
  },
});

return request.then((res) => res.data);
};

const saveWorkshop = (
    id,
    body,
    token,
    locale = "EN-CA"
) => {
  const request = axios.patch(`/bff/workshop/${id}`,
    body, {
        headers: {
            Authorization: "Bearer " + token,
            "Accept-Language": locale,
        },
  });

  return request.then((res) => res.data);
};

const WORKSHOP = {
  TYPE: {
    SINGLE_PARTICIPATION: "preparation",
    MULTIPLE_PARTICIPATION_SINGLE_SECTION: "visualisation",
    MULTIPLE_PARTICIPATION_MULTIPLE_SECTION: "evaluation",
  },
};

const isMultipleSectionsWorkshop = (workshop) => {
  return workshop.type === WORKSHOP.TYPE.MULTIPLE_PARTICIPATION_MULTIPLE_SECTION;
};

const isSingleSectionWorkshop = (workshop) => {
  return workshop.type === WORKSHOP.TYPE.MULTIPLE_PARTICIPATION_SINGLE_SECTION;
};

const isSingleWorkshop = (workshop) => {
  return workshop.type === WORKSHOP.TYPE.SINGLE_PARTICIPATION;
};

export default {
  getWorkshopTemplatesFromAssessment,
  getWorkshop,
  getPreparationWorkshop,
  saveWorkshop,
  isMultipleSectionsWorkshop,
  isSingleSectionWorkshop,
  isSingleWorkshop,
  WORKSHOP
};
