<template>

    <template v-if="stepResults != null">
        <template v-for="(result, index) in stepResults" :key="result.Id">
            <Fieldset :legend="result.text" :toggleable="true" :collapsed="true">
                <Step
                    :assessmentId="assessmentId"
                    :canEdit="canEdit"
                    :visualisationStepAnalysisResource="result.visualisationStepAnalysisResource"
                    :visualisationScenarioAnalysisResources="result.visualisationScenarioAnalysisResources"
                    @updateAnalysis="handleStepUpdate(index, $event)"
                />

            </Fieldset>
        </template>
    </template>
</template>
<script setup>
import { defineProps, onBeforeMount, ref } from "vue";
import BffResultService from '@/services/BffResultService';
import Step from './Step.vue';
import useAuth from "@/utils/auth";

const { getToken } = useAuth()
const stepResults = ref({})

let props = defineProps({
    assessmentId: String,
    workshop: Object,
    canEdit: Boolean
})

onBeforeMount (() => {
    getScenarioStepResult()
})

const getScenarioStepResult = () => {
    getToken().then(token=>{
        BffResultService.getVisualisationStepResult(
            props.workshop.id,
            props.assessmentId,
            token
        ).then(p=> {
            stepResults.value = p;
    })});
}

const handleStepUpdate = (index, updatedAnalysis) => {
    stepResults.value[index].visualisationStepAnalysisResource = updatedAnalysis;
};
</script>