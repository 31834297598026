<template>
    <h5 v-html="question.title"></h5>
    <div v-html="question.body"></div>
    <br/>

    <Dropdown
        v-model="radioValue[0]"
        :options="question.choices"
        optionLabel="text"
        optionValue="id"
        class="w-full"
        @change="$emit('patch-answer', {content: radioValue})" />
</template>

<script setup>

import { ref, defineProps,defineEmits } from 'vue'

defineEmits(["patch-answer"]);

let props = defineProps({
	question: Object,
    answer: Object,
	readOnly: Boolean
})

const radioValue = ref(props.answer.content ?? "")
</script>