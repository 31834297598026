import axios from "axios";

const getAssessment = (id, token, locale = "EN-CA") => {
  const request = axios.get(
    `/bff/assessment/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
        "Accept-Language": locale,
      },
    }
  );
  return request.then((res) => res.data);
};

const getMenu = (id, token, locale = "EN-CA") => {
  const request = axios.get(
    `/bff/assessment/${id}/menu`, {
      headers: {
        Authorization: "Bearer " + token,
        "Accept-Language": locale,
      },
    }
  );
  return request.then((res) => res.data);
};

export default {
  getAssessment,
  getMenu
};
