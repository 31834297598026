<template>
    <Toast/>
    <ConfirmDialog />
    <RecommendationDialog
        :opened="recommendationDialog.opened"
        :mode="recommendationDialog.mode"
        :recommendation="recommendationDialog.recommendation"
        @recommendationCreated="createRecommendation"
        @recommendationUpdated="updateRecommendation"
        @close='recommendationDialog.opened=false'
    />
    <div class="col-12" v-if="recommendations != null">
        <Card>
            <template #title>
                <Toolbar>
                    <template #start>
                        <i class="pi pi-pencil" style="font-size: 2rem;" ></i>
                        <h1 class="ml-2">{{ $t("recommendation.recommendations") }}</h1>
                    </template>
                </Toolbar>
            </template>

            <template #content>
                <Toolbar class="mb-4">
                    <template #start>
                        <Button v-if="acl.create" :label="$t('common.create')" icon="pi pi-plus" severity="success" class="mr-2" @click="openCreateModal" />
                        <Button v-if="acl.delete" :label="$t('common.delete')" icon="pi pi-trash" severity="danger" @click="confirmDeleteSelectedRecommendations" :disabled="!selectedRecommendations || !selectedRecommendations.length" />
                    </template>

                    <template #end>
                        <Button label="Export" icon="pi pi-upload" severity="help" @click="exportCSV($event)"  />
                    </template>
                </Toolbar>


                <!-- @rowReorder="onRowReorder" -->
                <DataTable
                    @update:multiSortMeta="updateMultiSortMeta"
                    :multiSortMeta="multiSortMeta"
                    :onRowReorder='onRowReorder'
                    :reorderableColumns="true"
                    removableSort
                    ref="dt"
                    :value="recommendations"
                    v-model:selection="selectedRecommendations"
                    dataKey="id"
                    sortMode="multiple">

                    <Column v-if="acl.write" :rowReorder="canReorder" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column  v-if="acl.delete" selectionMode="multiple" style="width: 3rem" :exportable="false" :reorderableColumn="false"></Column>
                    <Column field="sequence" :header="$t('recommendation.sequence')" sortable style="min-width:10rem" :reorderableColumn="false"></Column>
                    <Column field="title" :header="$t('recommendation.title')" sortable style="min-width:10rem" :reorderableColumn="false"></Column>
                    <Column field="theme" :header="$t('recommendation.thematic')" sortable style="min-width:10rem" :reorderableColumn="false"></Column>
                    <Column field="comment" :header="$t('recommendation.comment')" sortable style="min-width:10rem" :reorderableColumn="false"></Column>
                    <Column field="benefits" :header="$t('recommendation.benefits')" sortable style="min-width:10rem" :reorderableColumn="false"></Column>
                    <Column field="priority" :header="$t('recommendation.priority')" sortable style="min-width:10rem" :reorderableColumn="false"></Column>
                    <Column field="effort" :header="$t('recommendation.effort')" sortable style="min-width:2rem" :reorderableColumn="false"></Column>
                    <Column  v-if="acl.write" :exportable="false" style="min-width:8rem" :reorderableColumn="false">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" outlined rounded class="mr-2" @click="openEditModal(slotProps.data)" />
                        </template>
                    </Column>

                </DataTable>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, defineProps, computed } from 'vue';
import useAuth from "@/utils/auth"
import { useI18n } from 'vue-i18n'
import BffRecommendationService from '@/services/BffRecommendationService'
import SecurityService from '@/services/SecurityService'
import RecommendationDialog from './RecommendationDialog.vue';
import { useConfirm } from "primevue/useconfirm"

const { locale, t } = useI18n({ useScope: 'global' })
const { getToken } = useAuth()

const multiSort = ref(null)
const multiSortMeta = ref([{field: 'sequence', order: -1}])
const confirm = useConfirm()
const dt = ref();
const recommendations = ref(null);
const selectedRecommendations = ref([]);
const acl = ref({})

const recommendationDialog = ref({
    opened: false,
    mode: '',
    recommendation: null
})

let props = defineProps({
    assessmentId: String,
     readOnly:  {
        type: Boolean,
        default: function() { return true }
     }
})

onBeforeMount (() => {

    loadRecommendations()

    setPermission('CREATE')
    setPermission('WRITE')
    setPermission('DELETE')
})

const canReorder = computed(() => {
    return multiSort.value == null || multiSort.value.length == 0 || multiSort.value[0].field == 'sequence'
})


const setPermission = (permission) => {

    if (!props.readOnly) {
        getToken()
            .then(token => SecurityService.hasPermission(null, 'RECOMMENDATION', permission, token))
                .then(p => acl.value[permission.toLowerCase()] = p
            );
    }
}

const loadRecommendations = () => {
    getToken()
        .then(token => BffRecommendationService.getRecommendations(props.assessmentId, token, locale.value))
            .then(r => {
                recommendations.value = r
            }
        );
};

const openCreateModal = () => {
    recommendationDialog.value.mode = 'create'
    recommendationDialog.value.recommendation = null
    recommendationDialog.value.opened = true
};

const openEditModal = (recommendation) => {
    recommendationDialog.value.mode = 'update'
    recommendationDialog.value.recommendation = recommendation
    recommendationDialog.value.opened = true
};

const exportCSV = () => {
    dt.value.exportCSV();
};

const confirmDeleteSelectedRecommendations = () => {
    multiSortMeta.value[0].field = 'benefits'

    confirm.require({
        message: t("recommendation.confirmDeleteMessage"),
        header: t("common.confirmation"),
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        acceptLabel: t("common.yes"),
        rejectLabel: t("common.no"),
        accept: () => {
            getToken().then(token=> BffRecommendationService.deleteRecommendations(selectedRecommendations.value.map(r=>r.id), token)
                .then(()=>{
                    loadRecommendations()
                    selectedRecommendations.value = []
                }))
            }
    });
};

const createRecommendation = (recommendation) => {
    getToken().then(token=> BffRecommendationService.createRecommendation(props.assessmentId, recommendation, token)
        .then(()=>{
            loadRecommendations()
            recommendationDialog.value.opened = false
    }))
}

const updateRecommendation = (recommendation) => {
    getToken().then(token=> BffRecommendationService.updateRecommendation(recommendation, token)
        .then((r)=>{
            let index = recommendations.value.findIndex(r=>r.id == recommendation.id)
            recommendations.value[index] = r
            recommendationDialog.value.opened = false
    }))
}

const onRowReorder = (event)  => {
    let order = 'ASC'
    if (multiSort.value != null && multiSort.value.length != 0 ) {
        if (multiSort.value[0].order == -1) {
            order = 'DESC'
        }
    }

    getToken()
        .then(token => BffRecommendationService.moveRecommendation(recommendations.value[event.dragIndex].id, event.dropIndex, order, token, locale.value))
            .then(r => {
                recommendations.value = r
            }
        );

}

const updateMultiSortMeta = (sortMetadata)  => {
    multiSort.value = sortMetadata
}
</script>

