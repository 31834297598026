import axios from "axios";

const getVisualisationScenarioResult = (workshopId, assessmentId, token, locale = "EN-CA") => {
  const request = axios.get(
    `/bff/result/visualisation/${workshopId}/scenario`, {
      headers: {
        Authorization: "Bearer " + token,
        "Accept-Language": locale,
      },
      params: {
        assessmentId: assessmentId
      }
    }
  );
  
  return request.then((res) => res.data);
};

const getVisualisationStepResult = (workshopId, assessmentId, token, locale = "EN-CA") => {
  const request = axios.get(
    `/bff/result/visualisation/${workshopId}/step`, {
      headers: {
        Authorization: "Bearer " + token,
        "Accept-Language": locale,
      },
      params: {
        assessmentId: assessmentId
      }
    }
  );
  
  return request.then((res) => res.data);
};

const getEvaluationResult = (workshopId, assessmentId, token, locale = "EN-CA") => {
  const request = axios.get(
    `/bff/result/evaluation/${workshopId}`, {
      headers: {
        Authorization: "Bearer " + token,
        "Accept-Language": locale,
      },
      params: {
        assessmentId: assessmentId
      }
    }
  );
  
  return request.then((res) => res.data);
}

export default {
  getVisualisationScenarioResult,
  getVisualisationStepResult,
  getEvaluationResult
};
