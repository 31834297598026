<template>
    <WorkshopSingle
        :assessmentId="assessmentId"
        :answers="answers"
        :workshop="workshop"
        :workshopState="workshopState"
        :showSubmitButton="false"
    />

    {{ $t("result.analysisScope") }}
    <div class="h-2rem"></div>
    <Textarea v-model="analysis.analysis" class="w-full" :rows="5" :autoResize="true" @change="updatePreparationAnalysis" :disabled="!canEdit" />
</template>
<script setup>

import { defineProps, onBeforeMount, ref } from "vue";
import BffAnalysisService from '../../../services/BffAnalysisService.js';
import BffAnswerService from '../../../services/BffAnswerService.js';
import BffWorkshopStateService from "../../../services/BffWorkshopStateService";
import WorkshopSingle from '@/components/workshop/WorkshopSingle.vue';
import {useI18n} from 'vue-i18n';   
import useAuth from "@/utils/auth";

const {locale} = useI18n();
const { getToken } = useAuth();
const analysis  = ref({});
const answers = ref([]);
const workshopState = ref({});
const canEdit = ref(false);

let props = defineProps({
    assessmentId: String,
    workshop: Object
})

onBeforeMount (() => {
    loadAnswersFromWorkshop();
    getPreparationAnalysis();
    loadWorkshopState();
})

const updatePreparationAnalysis = () => {
    getToken().then(token=>{
        BffAnalysisService.updatePreparationAnalysis(
            props.assessmentId,
            {analysis: analysis.value.analysis,
             workshopId: props.workshop.id},
            token
    )});
}

const getPreparationAnalysis = () => {
    getToken().then(token =>
        BffAnalysisService.getPreparationAnalysis(
            props.workshop.id,
            props.assessmentId,
            token
    ).then(preparationAnalysis =>{
        console.log(preparationAnalysis);
        analysis.value = preparationAnalysis;
        canEdit.value = preparationAnalysis.acl.accesses.write;
    }));
}

const loadAnswersFromWorkshop = () => {
    getToken().then(token =>
        BffAnswerService.getAnswersFromWorkshop(props.workshop.id, token, locale.value).then(ans =>{
            answers.value = ans;
    }));
}

const loadWorkshopState = () => {
    getToken().then(token => BffWorkshopStateService.getWorkshopState(props.workshop.id, token, locale.value).then(ws => {
        workshopState.value = ws
    }));
}
</script>