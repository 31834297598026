<template>
    <Toast/>
    <ConfirmDialog />
    <div ref="root" class="col-12">
        <Card class="mb-3">
            <template #title><h1>{{$t('participations.title')}}</h1></template>
            <template #content>
                <Card class="mb-3">
                    <template #title>{{$t('participations.subtitle')}}</template>
                    <template #content>
                        <div class="grid">
                            <div class="col-11">
                                <div class="grid">
                                    <div class="col-3">
                                        <label for="name"> {{ $t("participations.inputs.name") }}</label>&nbsp;
                                        <InputText 
                                            id="name" 
                                            type="text" 
                                            placeholder="" 
                                            data-cy="input-name" 
                                            v-model="newParticipation.name" 
                                            :class="{'p-invalid': !newParticipationValid.name}" 
                                        />
                                    </div>
                                    <div class="col-3">
                                        <label for="email-address">{{ $t("participations.inputs.email") }}</label>&nbsp;
                                        <InputText 
                                            id="email-address" 
                                            type="text" 
                                            data-cy="input-mail"
                                            placeholder="" 
                                            v-model="newParticipation.email" 
                                            :class="{'p-invalid': !newParticipationValid.email}" 
                                        />
                                    </div>
                                    <div class="col-3">
                                        <label for="role">{{ $t("participations.inputs.role") }}</label>&nbsp;
                                        <InputText 
                                            id="role" 
                                            type="text" placeholder="" 
                                            v-model="newParticipation.role"
                                            data-cy="input-role-add"
                                            :class="{'p-invalid': !newParticipationValid.role}" 
                                        />
                                    </div>
                                    <div class="col-3">
                                        <label for="team">{{ $t("participations.inputs.team") }}</label>&nbsp;
                                        <InputText 
                                            id="team" 
                                            type="text" 
                                            placeholder="" 
                                            data-cy="input-team-add"
                                            v-model="newParticipation.team" 
                                            :class="{'p-invalid': !newParticipationValid.team}" 
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-1">
                                <Button class="invitation" :label='$t("participations.button.invite")' data-cy="button-add-participation" @click="addParticipation" />
                            </div>
                            <div class="grid">
                            <div class="col-12">
                                <div class="flex flex-row flex-wrap mt-3">
                                    <div v-for="(workshop, index) in workshopTemplates" :key="workshop.id" class="flex align-items-center justify-content-center m-2">
                                        <checkbox
                                            :id="workshop.id"
                                            class="checkbox mr-2"
                                            v-model="newParticipation.workshops[index]"
                                            :data-cy="`input-workshop-${index}-add`"
                                            :key="workshop.id"
                                            @change="changeNewParticipationWorkshop"
                                            :class="{'p-invalid': !newParticipationValid.workshops}"
                                            :binary="true"
                                        />
                                        <label class="checkbox-label mr-5">
                                            {{ workshop.label }}
                                        </label>
                                    </div>
                                    <div class="flex align-items-center justify-content-center m-2">
                                        <checkbox
                                            class="checkbox"
                                            v-model="newParticipation.allWorkshopsSelected"
                                            :binary="true"
                                            :data-cy="`input-workshop-all-add`"
                                            @change="changeAllWorkshopsSelected"
                                            />
                                        <label for=all-workshops-invitation class="checkbox-label">
                                            &nbsp;{{ $t("participations.workshop.allWorkshop") }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </template>
                </Card>
                
                <Card class="mb-3">
                    <template #title>{{ $t("participations.participationList.title") }}</template>
                    <template #content>
                        <div class="grid">
                            <div class="col-12">
                                <DataTable 
                                    id="participation-list"
                                    v-model:editingRows="editingRows" 
                                    tableClass="editable-cells-table" 
                                    editMode="row" 
                                    :value="participations" 
                                    @row-edit-init="onRowEditInit"
                                    @row-edit-save="onRowEditSave"
                                    @row-edit-cancel="onRowEditCancel"
                                    data-workshop-valid="true"
                                >
                                    <Column field="name" :header="$t('participations.participationList.name')" >
                                        <template  #editor="{ data, field }">
                                            <InputText 
                                            data-cy="input-name-edit" 
                                            v-model="data[field]"
                                            :class="{'p-invalid': isFieldInvalid(data[field])}"
                                            />
                                        </template>
                                    </Column>
                                    <Column data-cy="table-column-email" field="email" :header="$t('participations.participationList.email')" >
                                        <template #editor="{ data, field }">
                                            <InputText 
                                                data-cy="input-email-edit" 
                                                v-model="data[field]" 
                                                :class="{'p-invalid': isFieldInvalid(data[field])}"
                                            />
                                        </template>
                                    </Column>
                                    <Column field="role" :header="$t('participations.participationList.role')" >
                                        <template #editor="{ data, field }">
                                            <InputText 
                                                data-cy="input-role-edit" 
                                                v-model="data[field]" 
                                                :class="{'p-invalid': isFieldInvalid(data[field])}"
                                            />
                                        </template>
                                    </Column>
                                    <Column field="team" :header="$t('participations.participationList.team')">
                                        <template #editor="{ data, field }">
                                            <InputText 
                                                data-cy="input-team-edit"
                                                v-model="data[field]" 
                                                :class="{'p-invalid': isFieldInvalid(data[field])}"
                                            />
                                        </template>
                                    </Column>
                                    <Column v-for="(workshopTemplate,index) in workshopTemplates" :field="workshopTemplate" :key="workshopTemplate.id">
                                        <template #header="participation">
                                            <div class="w-full flex justify-content-center">
                                                {{participation.column.props.field.label}}
                                            </div>
                                        </template>
                                        <template #body="participation">
                                            <div class="flex justify-content-center">
                                                <i v-if="validateWorkshop(participation,workshopTemplate)" class="pi pi-check"></i>
                                            </div>
                                        </template>
                                        <template #editor="participation">
                                            <div class="flex justify-content-center">
                                                <checkbox
                                                    :id="workshopTemplate.id +'-'+ participation.data.id"
                                                    class="checkbox mr-2"
                                                    v-model="editingRows[0].workshopsPresent[index]"
                                                    :key="workshopTemplate.id +'-'+participation.data.id"
                                                    :data-cy="`input-workshop-${index}-edit`"
                                                    :class="{'p-invalid': !anyWorkshopSelected(editingRows[0].workshopsPresent, participation.data.id)}"
                                                    :binary="true"
                                                />
                                            </div>
                                        </template>
                                    </Column>
                                    <Column headerClass="column-icon">   
                                        <template #body="participation">
                                            <div class="flex justify-content-end">
                                                <Button :data-cy="`button-delete-participant-${participation.data.email}`" icon="pi pi-trash" @click="deleteParticipation(participation.data.id)" />
                                            </div>
                                        </template>
                                    </Column>
                                    <Column :rowEditor="true" :data-cy="edit-row-buttons" class="row-edit" bodyClass="text-center"></Column>
                                </DataTable>
                            </div>
                        </div>
                    </template>
                </Card>
            </template>
        </Card> 
    </div>  
</template>

<script setup>
    /* eslint-disable no-unused-vars*/
    import { ref, defineProps, defineEmits, onBeforeMount } from 'vue';
    import BffParticipationService from '@/services/BffParticipationService'
    import BffWorkshopService from '@/services/BffWorkshopService'
    import useAuth from "@/utils/auth"
    import $ from 'jquery'
    import { useI18n } from 'vue-i18n'
    import { useToast } from "primevue/usetoast"
    import {ToastSeverity} from 'primevue/api';
    import { useConfirm } from "primevue/useconfirm"

    const confirm = useConfirm()
    const toast = useToast()
    const {locale, t} = useI18n({ useScope: 'global' })
    const { getToken } = useAuth()
    const emit = defineEmits(['close'])

    const props = defineProps({
        assessmentId: String,
    });

    const newParticipation = ref({workshops: []})
    const newParticipationValid = ref({})
    const updatedParticipationValid = ref({})
    const editingRows = ref([])
    const root = ref(null)
    const participations = ref([])
    const workshopTemplates = ref([])

    const validateWorkshop = (participation,workshopTemplate) => {
        return  participation.data.workshopStates.find(w => w.workshopId === workshopTemplate.id) != null
    }

    const loadParticipations = () => {
        getToken().then(token => BffParticipationService.getParticipationFromAssessment(props.assessmentId, token)
        .then(participationList => {  
            participations.value = participationList
        }))
    }

    const loadWorkshopTemplates = () => {
        getToken().then(token => BffWorkshopService.getWorkshopTemplatesFromAssessment(props.assessmentId, token)
        .then(workshopTemplatesList => {  
            workshopTemplates.value = workshopTemplatesList
            workshopTemplatesList.forEach(()=> newParticipation.value.workshops.push(false))
        }))
    }

    const changeAllWorkshopsSelected = () => {
        for (var i = 0; i < newParticipation.value.workshops.length; i++) {
            newParticipation.value.workshops[i] = newParticipation.value.allWorkshopsSelected
        }
    }

    const changeNewParticipationWorkshop = () => {
        if (newParticipation.value.workshops.filter(w=>w).length == newParticipation.value.workshops.length) {
            newParticipation.value.allWorkshopsSelected = true
        } else {
            newParticipation.value.allWorkshopsSelected = false
        }
    }

    const anyWorkshopSelected = (workshops,participationId) => {
        if(participationId !== editingRows.value[0].id) 
            return true  

        let isDatatableValid = $(root.value).find("#participation-list").data('workshop-valid')
        let confirmButton = $(root.value).find(".p-row-editor-save")
        
        let workshopSelected = workshops.some(w => w === true) 

        if (!workshopSelected && isDatatableValid) {	
             toast.add({severity:ToastSeverity.ERROR, summary: t("common.error") , detail: t("participations.noWorkshopSelected"), life: 4000})
             $(root.value).find("#participation-list").data('workshop-valid',false)
        }
        confirmButton.prop("disabled", !workshopSelected)

        return workshopSelected
    }

    const isFieldInvalid = (field) => { 
        let isFieldInvalid = !field || field.trim() === ''
        $(root.value).find(".p-row-editor-save").prop("disabled",isFieldInvalid);

        return isFieldInvalid
    }

    const participationIsValid = (participation,participationValidation) => {
        let valid = true

        if (participation.name.trim() == "") {
            valid = false
            participationValidation.name = false
        }

        if (participation.email.trim() == "") {
            valid = false
            participationValidation.email = false
        }

        if (participation.role.trim() == "") {
            valid = false
            participationValidation.role = false
        }

        if (participation.team.trim() == "") {
            valid = false
            participationValidation.team = false
        }

        if (participation.workshops.filter(w=>w).length == 0) {
            toast.add({severity:ToastSeverity.ERROR, summary: t("common.error") , detail: t("participations.noWorkshopSelected"), life: 4000})
            valid = false
            participationValidation.workshops = false
        }
        return valid
    }

    const addParticipation = () => {
        if (participationIsValid(newParticipation.value, newParticipationValid.value)) {
            let participantRessource = getParticipationForPost(newParticipation.value) 
            participantRessource.workshopTemplatesIds = getWorkshopsForPost(newParticipation.value)

            getToken().then(token=> BffParticipationService.createParticipation(props.assessmentId, participantRessource, token)
                .then(p=>{
                    toast.add({severity:ToastSeverity.INFO, summary: t("common.success") , detail: t("participations.creationSuccessMessage"), life: 4000});
                    participations.value.push(p);
                    initNewParticipation();
            }))
        }
    }

    const deleteParticipation = (participationId) => {
        confirm.require({
            message: t("participations.confirmDeleteMessage"),
            header: t("common.confirmation"),
            icon: 'pi pi-info-circle',
            acceptClass: 'p-button-danger',
            acceptLabel: t("common.yes"),
            rejectLabel: t("common.no"),
            accept: () => {
                getToken().then(token=> BffParticipationService.deleteParticipation(props.assessmentId, participationId, token)
                    .then(()=>{                      
                        toast.add({severity:ToastSeverity.SUCCESS, summary: t("common.success"), detail: t("participations.deleteMessage"), life: 4000});  
                        let index = participations.value.map(p => p.id).indexOf(participationId);
                        participations.value.splice(index, 1);
                    }))
                }
        });
    }

    const getWorkshopsForPost = (participation) => {
        let workshopIds = [];

        for (var i = 0; i <workshopTemplates.value.length; i++) {
            if (participation.workshops[i]) {
                workshopIds.push(workshopTemplates.value[i].id)
            }
        }
        return workshopIds
    }

    const getParticipationForPost = (participation) => {
        return {
            name: participation.name,
            role: participation.role,
            team: participation.team,
            email: participation.email
        }
    }

    const initNewParticipation = () => {
        newParticipation.value.name = ""
        newParticipation.value.role = ""
        newParticipation.value.team = ""
        newParticipation.value.email = ""
        newParticipation.value.allWorkshopsSelected = false

        for (var i = 0; i < newParticipation.value.workshops; i++) {
            newParticipation.value.workshops[i] = false;
        }
        initParticipationValidation(newParticipationValid.value)
    }

    const initParticipationValidation = (participationValidation) => {
        participationValidation.name = true
        participationValidation.role = true
        participationValidation.team = true
        participationValidation.email = true
        participationValidation.workshops = true
    }

    const checkExistingWorkshops = (participant) => {
        let checkedWorkshops = [];

        for (let workshop of workshopTemplates.value) {
            if (participant.workshopTemplatesIds.find(id => id == workshop.id)) {
                checkedWorkshops.push(true);
            } else {
                checkedWorkshops.push(false);
            }
        }
        return checkedWorkshops;
    }

    const onRowEditInit = (event) => {
        editingRows.value[0].workshopsPresent = checkExistingWorkshops(event.data)
        $(root.value).find("#participation-list").data('workshop-valid',true)
        $(root.value).find(".p-row-editor-init").prop("disabled", true)
    }

    const onRowEditSave = (event) => {
        let { newData } = event;
        let updatedParticipation = newData;
        // We just need to send the ID of the workshop so we switch the choice to fit the logic in the backend
        
        updatedParticipation.workshops =  updatedParticipation.workshopsPresent
        initParticipationValidation(updatedParticipationValid.value)
        
        if(!participationIsValid(updatedParticipation,updatedParticipationValid.value)) {
            return false;
        }

        let participantRessource = getParticipationForPost(updatedParticipation) 
        participantRessource.workshopTemplatesIds = getWorkshopsForPost(updatedParticipation)
        participantRessource.id = updatedParticipation.id

        let index = participations.value.map(p => p.id).indexOf(participantRessource.id);
        participations.value[index] = participantRessource;
    
        getToken().then(token=>BffParticipationService.updateParticipation(props.assessmentId, participantRessource, token)
            .then(() => {
                toast.add({severity:ToastSeverity.INFO, summary: t("common.success"), detail: t("participations.updateSuccessMessage"), life: 4000});
            }));
        $(root.value).find(".p-row-editor-init").prop("disabled", false)
    }

    const onRowEditCancel = () => {
        $(root.value).find(".p-row-editor-init").prop("disabled", false)
    }

    onBeforeMount (() => {
        loadParticipations();
        loadWorkshopTemplates();
        initNewParticipation();
        initParticipationValidation(updatedParticipationValid.value)

    })
</script>

<style>
    .p-toolbar-group-start > h1 {
        margin-top: 1rem;
    }

    .row-edit {
        width: 10%;
        min-width: 8rem;
    }

    .column-icon {
        width:70px
    }
</style>