<template>

    <h5 v-html="question.body"></h5>

    <div  v-for="choice of question.choices" :key="choice.id" class="field-checkbox">
        <Checkbox
            :disabled="readOnly"
            name="choice"
            :value="choice.text"
            v-model="questionValue"
            @change="$emit('patch-answer', {content: questionValue})" />

        <label :for="choice.id">{{choice.text}}</label>
    </div>

</template>

<script setup>

import { ref, defineProps,defineEmits } from 'vue'

defineEmits(["patch-answer"]);

let props = defineProps({
	question: Object,
    answer: Object,
	readOnly: Boolean
})

let questionValue = ref(props.answer.content ?? "");

</script>