<template>
    <DataView :value="assessment.templates" :layout="layout" :paginator="false">
        <template #footer>
            <DataViewLayoutOptions v-model="layout" />
        </template>

        <template #list="slotProps">
            <div class="col-12">
                <div class="product-list-item">
                    <i class="pi mr-2" :class="slotProps.data.icon" style="font-size: 4rem"></i>
                    <div class="product-list-detail">
                        <div class="product-name mt-2">{{ slotProps.data.label }}</div>
                        <div class="product-description">{{ slotProps.data.title }}</div>
                    </div>
                    <div class="product-list-detail flex">
                        <div class="ml-2" v-tooltip="$t('dashboard.label.numberOfWorkshopCompletedTooltip')">
                            <i class="pi pi-user" style="font-size: 2rem;"></i>
                            <Badge severity="success" :value="slotProps.data.workshopTemplateState.numberOfCompletedWorkshop + '/' + slotProps.data.workshopTemplateState.numberOfParticipant" class="participationsCompleted-list"></Badge>
                        </div>

                    </div>
                    <div class="product-list-action">

                        <div class="mt-1 mb-3 flex align-items-center">
                            <ProgressBar class="progress-pink surface-300 border-round w-10rem lg:w-9rem" style="height:8px;" :value="parseInt(slotProps.data.workshopState ? slotProps.data.workshopState.progression : '0')" :showValue="false" />
                            <span class="p-progressbar-label text-pink-500 ml-2 font-medium">{{ parseInt(slotProps.data.workshopState ? slotProps.data.workshopState.progression : '0') }}%</span>
                        
                        </div>                            
                        <span>{{ slotProps.data.startDate }} - {{ slotProps.data.endDate }}</span>
                    </div>

                    <div class="product-list-action ml-2">
                        <div class="flex align-items-center">
                            <Button
                                :disabled="!slotProps.data.acl.accesses.read"
                                @click="router.push({path: '/workshop/' + slotProps.data.id})"
                                class="right mr-1"
                                :icon="workshopViewButtonIcon(slotProps.data)"
                                :title="workshopViewButtonText(slotProps.data)" />

                            <Button
                                v-if="isUserRoleCompanyAdmin()"
                                :disabled="!slotProps.data.acl.accesses.readResults"
                                @click="router.push({ path: '/result/'+ props.assessment.workshopId +'/workshop/' + slotProps.data.id})"
                                class="right"
                                icon="pi pi-chart-pie"
                                :title="t('dashboard.button.analysis')" />
                        </div>
                    </div>                            
                </div>
            </div>
        </template>
 
        <template #grid="slotProps">
            <div class="col-12 md:col-4">
                <div class="product-grid-item card">
                    <div v-if="slotProps.data.workshopTemplateState" class="product-grid-item-top">
                        <div class="flex w-12 justify-content-end" v-tooltip="$t('dashboard.label.numberOfWorkshopCompletedTooltip')">
                            <i class="pi pi-user" style="font-size: 2rem;"></i>
                            <Badge severity="success" :value="slotProps.data.workshopTemplateState.numberOfCompletedWorkshop + '/' + slotProps.data.workshopTemplateState.numberOfParticipant" class="participationsCompleted-grid"></Badge>
                        </div>
                    </div>
                    <div class="product-grid-item-content">
                        <i class="pi pi-user mr-2" style="font-size: 4rem"></i>
                        <div class="product-name mt-2">{{ slotProps.data.label }}</div>
                        <div class="product-description">{{ slotProps.data.title }}</div>
                        <div class="product-description">{{ slotProps.data.startDate }}/{{ slotProps.data.endDate }}</div>
                    </div>
                    <div class="product-grid-item-bottom">
                        <span class="flex align-items-center">
                            <ProgressBar
                                v-if="slotProps.data.acl.accesses.read"
                                v-tooltip="$t('dashboard.label.progressBarTooltip')"
                                class="progress-pink surface-300 border-round w-10rem lg:w-6rem"
                                        style="height:8px;" :value="parseInt(slotProps.data.workshopState ? slotProps.data.workshopState.progression : '0')" :showValue="false" />
                            <span class="p-progressbar-label text-pink-500 ml-2 font-medium">{{ slotProps.data.workshopState ? slotProps.data.workshopState.progression : '0' }} %</span>
                        </span>
                        <div class="flex justify-content-end flex-wrap card-container green-container">
                            <div class="flex align-items-center justify-content-center">
                                <Button
                                    class="mr-1"
                                    :disabled="!slotProps.data.acl.accesses.read"
                                    @click="router.push({path: '/workshop/' + slotProps.data.id})"
                                    :icon="workshopViewButtonIcon(slotProps.data)"
                                    :title="workshopViewButtonText(slotProps.data)" />
                            </div>
                            <div class="flex align-items-center justify-content-center">
                                <Button
                                    v-if="isUserRoleCompanyAdmin()"
                                    :disabled="!slotProps.data.acl.accesses.readResults"
                                    @click="router.push({ path: '/result/'+ props.assessment.id +'/workshop/' +  slotProps.data.id})"
                                    icon="pi pi-chart-pie"
                                    :title="t('dashboard.button.analysis')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </DataView>
</template>

<script setup>

import useAuth from "@/utils/auth";
import { ref, defineProps} from "vue";
import router from "@/router";

const { t } = useI18n({ useScope: 'global' });
import { useI18n } from 'vue-i18n';
const { isUserRoleCompanyAdmin } = useAuth();
const layout = ref('grid');

let props = defineProps({
    assessment: Object
});

const workshopViewButtonText = (workshop) => {
    if (workshop.acl.accesses.write) {
        return t("dashboard.button.participant")
    } else {
        return t("dashboard.button.summary")
    }
}

const workshopViewButtonIcon = (workshop) => {
    if (workshop.acl.accesses.write) {
        return 'pi pi-file-edit'
    } else {
        return 'pi pi-eye'
    }
}
</script>

<style lang="scss" scoped>

.participationsCompleted-grid {
    transform: translate(-10%,-50%);
}

.participationsCompleted-list {
    transform: translate(-10%,-110%);
}

.card {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
}
.p-dropdown {
    width: 14rem;
    font-weight: normal;
}

.product-name {
	font-size: 1.5rem;
	font-weight: 700;
}

.product-description {
	margin: 0 0 1rem 0;
}

.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}

.product-category {
	font-weight: 600;
	vertical-align: middle;
}

::v-deep(.product-list-item) {
	display: flex;
	align-items: center;
	padding: 1rem;
	width: 100%;

	img {
		width: 50px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin-right: 2rem;
	}

	.product-list-detail {
		flex: 1 1 0;
	}

	.p-rating {
		margin: 0 0 .5rem 0;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: .5rem;
		align-self: flex-end;
	}

	.product-list-action {
		display: flex;
		flex-direction: column;
	}

	.p-button {
		margin-bottom: .5rem;
	}
}

::v-deep(.product-grid-item) {
	margin: .5rem;
	border: 1px solid var(--surface-border);

	.product-grid-item-top,
	.product-grid-item-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	img {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin: 2rem 0;
	}

	.product-grid-item-content {
		text-align: center;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
	}
}

@media screen and (max-width: 576px) {
	.product-list-item {
		flex-direction: column;
		align-items: center;

		img {
			margin: 2rem 0;
		}

		.product-list-detail {
			text-align: center;
		}

		.product-price {
			align-self: center;
		}

		.product-list-action {
			display: flex;
			flex-direction: column;
		}

		.product-list-action {
			margin-top: 2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
}
</style>