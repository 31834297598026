<template>
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar v-if="company.id" @menu-toggle="onMenuToggle" :company="company"
            :currentAssessmentId="currentAssessment.id"
            :assessments="assessmentList"
        />
        <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>

        <div class="layout-main-container">
            <div class="layout-main">
                <div class="grid">
                    <router-view v-if="currentAssessment.id"
                        :key="$route.path"
                        :assessment="currentAssessment"
                        :assessmentId="currentAssessmentId" />
                </div>
            </div>
            <AppFooter />
        </div>


        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
    </div>
</template>

<script>
import { computed } from 'vue';
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import CompanyService from './services/CompanyService';
import { useI18n } from 'vue-i18n';
import useAuth from "@/utils/auth";
import ParticipationService from '../src/services/ParticipationService';
import EventBus from './AppEventBus';
import axios from "axios";
import AssessmentService from './services/AssessmentService';
import BffAssessmentService from './services/BffAssessmentService';
import WorkshopService from './services/WorkshopService';
import getEnv from "@/utils/env";

export default {
    emits: ['change-theme'],
    data() {
        return {
            firstLoad: true,
            locale: null,
            isUserRoleCompanyAdmin: null,
            isUserRoleAssessmentOwner: null,
            participation: { id: null, workshops: [] },
            company: { id: null, assessments: [] },
            currentAssessment: { id: null },
            currentAssessmentId: null,
            assessmentList: null,
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            // instead use const { getToken } = useAuth() with composition api
            getToken: null,
            user: this.$auth0.user,
            menu: [{
                label: 'GoDevOps',
                items: []
            }],
            menuMap: {
            }
        }
    },
    provide() {
        return {
            company: computed(() => this.company),
            currentAssessment: computed(() => this.currentAssessment),
        }
    },

    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return "images/logo-dark.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
    },

    beforeMount() {
        const { getToken, isUserRoleCompanyAdmin, isUserRoleAssessmentOwner } = useAuth()
        const { locale } = useI18n()
        this.getToken = getToken
        this.locale = locale
        this.isUserRoleCompanyAdmin = isUserRoleCompanyAdmin
        this.isUserRoleAssessmentOwner = isUserRoleAssessmentOwner

        let redirectOnError = JSON.parse(getEnv("VUE_APP_REDIRECT_ON_ERROR"))

        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (!redirectOnError) {
                    console.log("Error happens : ")
                    console.log(error)
                    confirm("An error happens. View console fore more details.\nSet VUE_APP_REDIRECT_ON_ERROR env variable to true to redirect to error page.")
                } else {
                    switch (error.response.data.status) {
                        case 404:
                            this.$router.push({name: 'notFound', params: {errorMessage: error.response.data.message , errorStatus: error.response.data.status } })
                            break;
                        case 401:
                            this.$router.push({ name: 'unauthorized', params: {errorStatus: error.response.status} } )
                            break;
                        case 403:
                            this.$router.push({ name: 'forbidden', params: {errorStatus: error.response.status} } )
                            break;
                        default:
                            this.$router.push({ name: 'error', params: {errorStatus: error.response.status} } )
                    }
                }
            }
        );
        EventBus.on("assessment-selected", this.selectAssessment);

        if (this.isUserRoleCompanyAdmin() || this.isUserRoleAssessmentOwner()) {
            Promise.all([this.getCompanyPromise(), this.getParticipationPromise()]).then(values => {
                Object.assign(this.company, values[0].data)
                Object.assign(this.participation, values[1].data)
                Object.assign(this.currentAssessment, this.company.assessments[0])
                this.assessmentList = this.company.assessments
                this.currentAssessmentId = this.currentAssessment.id
                this.initialLoad();
            })
        } else {
            this.getParticipantAssessmentPromise().then(assessments => {
                this.assessmentList = assessments.data
                this.currentAssessmentId = this.assessmentList[0].id
            })
                .then(() => {
                    this.getParticipationPromise().then(participation => {
                        Object.assign(this.participation, participation.data)
                        this.getParticipationCompanyPromise(this.currentAssessmentId).then(company => {
                            this.company = company.data
                            this.currentAssessment = this.company.assessments[0]
                            this.initialLoad();
                        })
                    })
                })
        }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        },
        getCompanyPromise() {
            return this.getToken()
                .then(token => CompanyService.getCompanyPromise(token, this.locale))
        },
        getParticipationCompanyPromise(assessmentId) {
            return this.getToken()
                .then(token => CompanyService.getParticipationCompanyPromise(token, assessmentId))
        },
        getParticipantAssessmentPromise() {
            return this.getToken()
                .then(token => AssessmentService.getParticipantAssesmentsList(token))
        },
        getParticipationPromise() {
            return this.getToken()
                .then(token => ParticipationService.getParticipationByEmailPromise(token, this.user.email))
        },
        initialLoad() {
            this.firstLoad = false
            this.buildMainMenu()
        },
        buildMainMenu() {
            this.menu[0].items.splice(0)
            this.getToken().then(token =>
                BffAssessmentService.getMenu(this.currentAssessmentId, token).then(menu =>
                    this.menu[0].items = menu
            ))
        },
        selectAssessment(assessmentId) {
            this.currentAssessment = this.assessmentList.find(a => a.id == assessmentId);
            this.buildMainMenu()
            this.$router.push('/dashboard/')
        },
        sortWorkshops(workshops) {
            let singleWorkshops = workshops.filter(w => WorkshopService.isSingleWorkshop(w))
            let multipleSectionsWorkshops = workshops.filter(w => WorkshopService.isMultipleSectionsWorkshop(w))
            let singleSectionWorkshops = workshops.filter(w => WorkshopService.isSingleSectionWorkshop(w))

            return singleWorkshops.concat(multipleSectionsWorkshops, singleSectionWorkshops)
        }
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
