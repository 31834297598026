<template>
    <div ref="root">
        <Button
            :label='setAsideCommentsHidden ? $t("result.question.commentsButton.show") : $t("result.question.commentsButton.hide")'
            @click="changeSetAsideCommentsVisibility"
            :badge="setAsideCommentsHidden ? participations[1].length.toString() : ''"
         />

        <div class="h-3rem"></div>

        <PickList
            v-model="participations"
            dataKey="id"
            :stripedRows="true"
            @reorder="handlePickListEvent"
            @move-all-to-target="moveAllToRelevantList"
            @move-all-to-source="moveAllToSetAsideList"
            @move-to-target="handlePickListEvent"
            @move-to-source="handlePickListEvent">

            <template #sourceheader>
                <div class="picklist-table-name-section">
                    <i class="pi pi-comments mr-2" style="font-size: 2rem;" v-badge.warning="(participations == null ? 0 : participations[0].length)"></i>
                    <span class="text-primary font-semibold text-xl picklist-table-name"> {{ $t("result.comments") }}</span>
                </div>

                <div class="grid product-item text-color">

                    <div class="col-2">
                        {{ $t("result.name") }}
                    </div>
                    <div class="col-3">
                        {{ $t("result.problematic") }}
                    </div>
                    <div class="col-3">
                        {{ $t("result.improvement") }}
                    </div>
                    <div class="col-3">
                        {{ $t("result.impact") }}
                    </div>
                    <div class="col-1">
                        {{ $t("result.steps") }}
                    </div>

                </div>
            </template>
            <template #targetheader>

                <div class="picklist-table-name-section">
                    <i class="pi pi-comments p-text-secondary mr-2" style="font-size: 2rem;" v-badge.danger="participations == null ? 0 : participations[1].length"></i>
                    <span class="text-primary font-semibold text-xl picklist-table-name"> {{ $t("result.hiddenComments") }}</span>
                </div>
                <div class="grid product-item text-color-secondary">
                    <div class="col-2">
                        {{ $t("result.name") }}
                    </div>
                    <div class="col-3">
                        {{ $t("result.problematic") }}
                    </div>
                    <div class="col-3">
                        {{ $t("result.improvement") }}
                    </div>
                    <div class="col-3">
                        {{ $t("result.impact") }}
                    </div>
                    <div class="col-1">
                        {{ $t("result.steps") }}
                    </div>
                </div>
            </template>
            <template #item="slotProps">
                <div class="grid product-item">

                    <div class="col-2">
                        {{slotProps.item.name}}
                    </div>
                    <div class="col-3">
                        {{slotProps.item.problem}}
                    </div>
                    <div class="col-3">
                        {{slotProps.item.improvement}}
                    </div>
                    <div class="col-3">
                        {{slotProps.item.impact}} 
                    </div>
                    <div class="col-1">
                        {{slotProps.item.steps.join(",")}}
                    </div>
                </div>
            </template>
        </PickList>

        <div class="h-3rem"></div>

        <DataTable :value="visualisationScenarioAnalyses" v-if="visualisationScenarioAnalyses" :rowClass="stepRowClass">
            <Column field="stepName" :header="$t('result.step')" class="max-w-2rem"></Column>
            <Column :header="$t('result.level')" class="max-w-3rem">
                <template #body="slotProps">
                    <Dropdown v-model="slotProps.data.impactLevel" :options="levelSelect" optionLabel="level" optionValue="level" :editable="true" @change="updateResult" :disabled="!canEdit"/>
                </template>
            </Column>
            <Column :header="$t('result.problematic')">
                <template #body="slotProps">
                    <Textarea v-model="slotProps.data.problem" class="w-full" :rows="5" :autoResize="true" @change="updateAnalysis(slotProps.data)" :disabled="!canEdit"/>
                </template>
            </Column>
            <Column :header="$t('result.improvement')">
                <template #body="slotProps">
                    <Textarea v-model="slotProps.data.improvement" class="w-full" :rows="5" :autoResize="true" @change="updateAnalysis(slotProps.data)" :disabled="!canEdit"/>    
                </template>
            </Column>
            <Column :header="$t('result.impact')">
                <template #body="slotProps">
                    <Textarea v-model="slotProps.data.impact" class="w-full" :rows="5" :autoResize="true" @change="updateAnalysis(slotProps.data)" :disabled="!canEdit"/>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script setup>
import { defineProps, ref, onBeforeMount, onMounted} from "vue";
import useAuth from "@/utils/auth";
import $ from 'jquery'
import BffAnalysisService from "../../../services/BffAnalysisService";

const { getToken } = useAuth()

let props = defineProps({
    participationsScenario: Object,
    visualisationScenarioAnalyses: Object,
    assessmentId: String,
    workshopId: String,
    canEdit: Boolean
})

const participations = ref(null)
const setAsideCommentsHidden = ref(false)
const root = ref(null)
const levelSelect = ref([])

onBeforeMount (() => {
    for (let i=0; i<=10; i++) {
        levelSelect.value.push({
            level: i
        })
    }
})

onMounted (() => {
    changeSetAsideCommentsVisibility()
    participations.value = initPickListData()
})

const initPickListData = () => {
    let relevantList = []
    let setAsideList = []
    let settings = {
        relevantList: [],
        setAsideList: []
    }

    populatePickList(settings.relevantList, relevantList)
    populatePickList(settings.setAsideList, setAsideList)

    // Check if there is a participant that is not in the list
    props.participationsScenario.forEach(p=>{
        relevantList.push(p)    
    })

    return [relevantList, setAsideList]
}

const populatePickList = (settingsList, tableList) => {
    settingsList.forEach(id=>{
        let participation = props.participationsScenario.find(p=>p.id == id)
        if (participation != null) {
            tableList.push(participation)
        }
    })
}

// const handlePickListEvent = () => {
//     updateSettings(getSettingsFromList(participations.value))
// }

const moveAllToSetAsideList = (event) => {
    let list = [participations.value[0].filter(()=>true), []]
    
    event.items.forEach(i=>
        list[0].push(i)
    )

    // updateSettings(getSettingsFromList(list))
}

const moveAllToRelevantList = (event) => {
    let list = [[], participations.value[1].filter(()=>true)]
    event.items.forEach(i=>
        list[1].push(i)
    )
    
    // updateSettings(getSettingsFromList(list))
}

// const getSettingsFromList = (list) => {
//     let settings = {
//         relevantList: [],
//         setAsideList: []
//     }
//     list[0].forEach(p=>
//         settings.relevantList.push(p.id)
//     )

//     list[1].forEach(p=>
//         settings.setAsideList.push(p.id)
//     )

//     return settings
// }

const updateAnalysis = (analysis) => {
    getToken().then(token=>{
        BffAnalysisService.updateVisualisationScenarioAnalysis(props.assessmentId, analysis ,token)
    });
}; 

const changeSetAsideCommentsVisibility = () => {
    setAsideCommentsHidden.value = !setAsideCommentsHidden.value

    $(root.value).find(".p-picklist-list-wrapper.p-picklist-target-wrapper").toggleClass("invisible")
    $(root.value).find(".p-picklist-buttons.p-picklist-target-controls").toggleClass("invisible")
    $(root.value).find(".p-picklist-buttons.p-picklist-transfer-buttons").find("button:nth-child(3)").toggleClass("invisible")
    $(root.value).find(".p-picklist-buttons.p-picklist-transfer-buttons").find("button:nth-child(4)").toggleClass("invisible")
}

const stepRowClass = (rowData) => {
    let theClass = '';

    if (rowData.selected) {
        theClass = "surface-100"
    }

    return theClass;
}
</script>