<template>
  <div v-if="company.id" class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img v-if="logo != null" alt="Logo" :src="logo" />
      <span>{{ company.name }}</span>
    </router-link>
    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <button class="p-link layout-topbar-menu-button layout-topbar-button" v-styleclass="{
      selector: '@next',
      enterClass: 'hidden',
      enterActiveClass: 'scalein',
      leaveToClass: 'hidden',
      leaveActiveClass: 'fadeout',
      hideOnOutsideClick: true,
    }">
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
        <SelectAssessment class="mt-1" v-if="company.id" :assessments="assessments"
          :currentAssessmentId="currentAssessmentId" />
      </li>
      <li v-if="!isAuthenticated">
        <button class="p-link layout-topbar-button">
          <i class="pi pi-user"></i>
          <span>Profile</span>
        </button>
      </li>
      <li v-if="!isAuthenticated">
        <button @click="login" class="p-link layout-topbar-button">
          <i class="pi pi-sign-in"></i>
          <span>Login</span>
        </button>
      </li>
      <li v-if="isAuthenticated">
        <Avatar shape="circle" class="layout-topbar-button" :image="user.picture" />
      </li>
      <li v-if="isAuthenticated">
        <button @click="logoutUser" class="p-link layout-topbar-button">
          <i class="pi pi-sign-out"></i>
          <span>Sign out</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup>
import companyService from "./services/CompanyService.js";
import { useAuth0 } from "@auth0/auth0-vue";
import { ref, defineEmits, onBeforeMount, inject, defineProps } from "vue";
import useAuth from "@/utils/auth";
import SelectAssessment from "./components/topbar/SelectAssessment.vue";

const { getToken } = useAuth();
const { loginWithRedirect, user, isAuthenticated, logout } = useAuth0();
const logo = ref(null);
const company = ref({ id: null });
company.value = inject("company").value;


const emit = defineEmits(["menu-toggle"]);

defineProps({
  company: Object,
  currentAssessmentId: String,
  assessments: Array
});

const onMenuToggle = (event) => {
  emit("menu-toggle", event);
};

const login = () => {
  loginWithRedirect();
};

const logoutUser = () => {
  logout({ returnTo: window.location.origin });
};

onBeforeMount(() => {
  getToken().then((token) => {
    companyService.getImage(company.value.logo.id, token).then((data) => {
      logo.value = data;
    });
  });
});
</script>

