/* eslint-disable no-unused-vars*/
import axios from "axios";

const getParticipationFromAssessment = async (assessmentId,token) => {
  const url = "bff/participant";
  const response = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      assessmentId: assessmentId,
    },
  });

  return response.data;
};

const createParticipation = (
  assessmentId,
  participation,
  token) => {
  const url = "bff/participant"
  const request = axios.post(url, participation, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      assessmentId: assessmentId,
    },
  });
  return request.then((res) => res.data);
};

const updateParticipation = async (
  assessmentId,
  participation,
  token) => {
  const url = `bff/participant/${participation.id}`;
  const response = await axios.patch(url, participation, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      assessmentId: assessmentId,
    },
  });
  return response.data;
};


const deleteParticipation = async (
  assessmentId,
  participationId,
  token) => {
  const url = `bff/participant/${participationId}`;  
  const response = await axios.delete(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      assessmentId: assessmentId,
    },
  });
  return response.data;
};

export default {
  createParticipation,
  updateParticipation,
  deleteParticipation,
  getParticipationFromAssessment
};
