import axios from "axios";

const getReportWorkshopByAssessmentIdAndWorkshopId = (
  workshopId,
  assessmentId,
  token,
  locale = "EN-CA"
) => {
  const request = axios.get(
    "/workshop/" + workshopId + "/report/assessment/" + assessmentId,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Accept-Language": locale,
      },
    }
  );

  return request.then((res) => res.data);
};

const WORKSHOP = {
  TYPE: {
    SINGLE_PARTICIPATION: "preparation",
    MULTIPLE_PARTICIPATION_SINGLE_SECTION: "visualisation",
    MULTIPLE_PARTICIPATION_MULTIPLE_SECTION: "evaluation",
  },
};

const isMultipleSectionsWorkshop = (workshop) => {
  return workshop.type === WORKSHOP.TYPE.MULTIPLE_PARTICIPATION_MULTIPLE_SECTION;
};

const isSingleSectionWorkshop = (workshop) => {
  return workshop.type === WORKSHOP.TYPE.MULTIPLE_PARTICIPATION_SINGLE_SECTION;
};

const isSingleWorkshop = (workshop) => {
  return workshop.type === WORKSHOP.TYPE.SINGLE_PARTICIPATION;
};

export default {
  getReportWorkshopByAssessmentIdAndWorkshopId,
  isMultipleSectionsWorkshop,
  isSingleSectionWorkshop,
  isSingleWorkshop,
  WORKSHOP
};
