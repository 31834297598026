<template >
    <div class="col-12" v-if="assessment.id">
        <Card>
            <template #title>
                <h2>
                    {{ assessment.initiative }}
                    <Button type="button" icon="pi pi-info-circle" label="" severity="primary" text size="large" @click="visible = true" />                    
                </h2>              

                <Dialog v-model:visible="visible" modal :header="assessment.initiative" :style="{ width: '50vw' }">
                    <p v-html="introText"></p>
                </Dialog> 
            </template>
            
            <template #content>               
                <em class="p-text-light">{{$t("dashboard.label.from")}} {{ assessment.startDate }} {{$t("dashboard.label.to")}} {{ assessment.endDate }}</em>
                <Workshops v-if="assessment.id" :assessment="assessment" />
            </template>
        </Card>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, defineProps } from 'vue';
import ContentTextService from '../services/ContentTextService';
import BffAssessmentService from '../services/BffAssessmentService';
import BffWorkshopStateService from "../services/BffWorkshopStateService";
import Workshops from '../components/dashboard/Workshops.vue';
import { useI18n } from 'vue-i18n';
import useAuth from "@/utils/auth";
const { isUserRoleCompanyAdmin, isUserRoleAssessmentOwner } = useAuth();


const { getToken } = useAuth();
const { locale } = useI18n({ useScope: 'global' });
const introText = ref("");
const assessment = ref({ id: null });
const visible = ref(false);

let props = defineProps({
    assessmentId: String
})

onBeforeMount(() => {
    loadAssessment() 
    getToken()
        .then(token => ContentTextService.getContentByKey('dashboard_intro', token, locale.value))
            .then(res => introText.value = res.text);
});

const loadAssessment = () => {
    getToken()
        .then(token => BffAssessmentService.getAssessment(props.assessmentId, token, locale.value))
            .then(a => { 
                loadWorkshopStates(a) 
            });
}

const loadWorkshopStates = (a) => {
    getToken().then(token => BffWorkshopStateService.getWorkshopStates(props.assessmentId, token).then(response => {
    for (let i = 0; i < response.length; i++) {
        a.templates.find(t => t.id == response[i].workshopId)["workshopState"] = response[i]; 
    }
    if(isUserRoleAssessmentOwner() || isUserRoleCompanyAdmin()) {
            getToken().then(token => BffWorkshopStateService.getWorkshopStatesCompleted(props.assessmentId, token).then(response => {
                for (let i = 0; i < response.length; i++) {
                    a.templates.find(t => t.id == response[i].workshopId)["workshopTemplateState"] = response[i]; 
                }
                assessment.value = a; 
            }));
        }
        else {
            assessment.value = a;
        }
    }));
};
</script>
