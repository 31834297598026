import axios from "axios";

const hasPermission = async (targetId, targetType, permission, token) => {

    const request = axios.get(`/security/has-permission`, {
        headers: {
            Authorization: "Bearer " + token
        },
        params: {
          targetId: targetId,
          targetType: targetType,
          permission: permission
        }
    })

  return request.then((res) => res.data)
}

export default {
  hasPermission
}
