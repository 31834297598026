<template>
    <template v-if="workshop.id" >
        <div class="col-12">
            <Card class='mb-3'>
                <template #title>
                    <h2>
                        {{workshop.title}} 
                        <Button type="button" icon="pi pi-info-circle" label="" severity="primary" text size="large" @click="visible = true" />
                    </h2>

                    <Dialog v-model:visible="visible" modal :header="workshop.title" :style="{ width: '50vw' }">
                        <p v-html="workshop.body" ></p>
                    </Dialog>
                </template>
                <template #content>                 
                    <template v-for="question of survey.questions" :key="question.id">
                            <QuestionRadioAndComment
                                v-if="question.type == 'radio + comment'"
                                :question="question"
                                :answer="answers.filter(answer => answer.questionId == question.id)[0]"
                                :readOnly="!workshop.acl.accesses.write"
                                @patch-answer="(value) => $emit('patch-answer', value, answers.filter(answer => answer.questionId == question.id)[0].id)" />

                            <QuestionRadio
                                v-if="question.type == 'radio'"
                                :question="question"
                                :answer="answers.filter(answer => answer.questionId == question.id)[0]"
                                :readOnly="!workshop.acl.accesses.write"
                                @patch-answer="(value) => $emit('patch-answer', value, answers.filter(answer => answer.questionId == question.id)[0].id )" />

                            <QuestionCheckbox
                                v-if="question.type == 'checkbox'"
                                :question="question"
                                :answer="answers.filter(answer => answer.questionId == question.id)[0]"
                                :readOnly="!workshop.acl.accesses.write"
                                @patch-answer="(value) => $emit('patch-answer', value, answers.filter(answer => answer.questionId == question.id)[0].id )" />

                            <QuestionTextarea
                                v-if="question.type == 'textArea'"
                                :question="question"
                                :answer="answers.filter(answer => answer.questionId == question.id)[0]"
                                :readOnly="!workshop.acl.accesses.write"
                                @patch-answer="(value) => $emit('patch-answer', value, answers.filter(answer => answer.questionId == question.id)[0].id )" />
                    </template>
                </template>
                <template #footer>
                    <div  v-if="showSubmitButton" class="flex flex-row-reverse">
                        <Button 
                            @click="$emit('submit-workshop')"
                            :disabled="!workshop.acl.accesses.write || workshopState.progression != 100">
                                {{ $t("evaluation.submit")}}
                            </Button>
                    </div>
                </template>
            </Card>
        </div>
    </template>
</template>

<script setup>
import {defineEmits, ref} from 'vue';
import QuestionRadioAndComment from './questions/QuestionRadioAndComment.vue';
import QuestionRadio from './questions/QuestionRadio.vue';
import QuestionCheckbox from './questions/QuestionCheckbox.vue';
import QuestionTextarea from './questions/QuestionTextarea.vue';
import {defineProps } from 'vue'

defineEmits(['patch-answer', 'submit-workshop'])

let props = defineProps({
	workshop: Object,
    workshopState: Object,
	assessmentId: String,
    answers: Array,
    showSubmitButton: {type: Boolean, default: true}
})

const survey = ref(props.workshop.sections[0].surveys[0])
const visible = ref(false);
</script>
