import axios from "axios";

const getPreparationAnalysis = (workshopId,assessmentId, token, locale = "EN-CA") => {
  const request = axios.get(
    `/bff/analysis/preparation/${workshopId}`, {
      headers: {
        Authorization: "Bearer " + token,
        "Accept-Language": locale,
      },
      params: {
        assessmentId: assessmentId,
      },
    }
  );
  
  return request.then((res) => res.data);
};

const updatePreparationAnalysis = async (assessmentId, analysis, token) => {
  const url = `bff/analysis/preparation`;
  
  const response = await axios.patch(url, analysis, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      assessmentId: assessmentId,
    },
  });

  return response.data;
};

const updateVisualisationScenarioAnalysis = (assessmentId, analysis, token) => {
  const url = `bff/analysis/visualisation/scenario`;
  const request = axios.patch(url, analysis, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      assessmentId: assessmentId,
    },
  });
  
  return request.then((res) => res.data);
};

const updateVisualisationStepAnalysis = ( assessmentId, analysis, token) => {
  const url = `bff/analysis/visualisation/step`;
  
  const request = axios.patch(url, analysis, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      assessmentId: assessmentId,
    },
  });
  
  return request.then((res) => res.data);
};

const updateSectionEvaluationAnalysis = ( assessmentId, analysis, token) => {
  const url = `bff/analysis/evaluation/section`;
  
  const request = axios.patch(url, analysis, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      assessmentId: assessmentId,
    },
  });
  
  return request.then((res) => res.data);
};

const updateSurveyEvaluationAnalysis = ( assessmentId, analysis, token) => {
  const url = `bff/analysis/evaluation/survey`;
  
  const request = axios.patch(url, analysis, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      assessmentId: assessmentId,
    },
  });
  
  return request.then((res) => res.data);
};

export default {
  getPreparationAnalysis,
  updatePreparationAnalysis,
  updateVisualisationScenarioAnalysis,
  updateVisualisationStepAnalysis,
  updateSectionEvaluationAnalysis,
  updateSurveyEvaluationAnalysis
};
