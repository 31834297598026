<template>
  <div >
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`lang-${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script setup>
import {ref,} from 'vue'

let langs = ref(['en', 'fr']) 

</script>