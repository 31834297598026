import { createRouter, createWebHashHistory } from "vue-router";

import App from "./App.vue";
import Dashboard from "./views/Dashboard.vue";
import Participation from "./views/Participation.vue";
import Recommendations from "./views/Recommendations.vue";
import Result from "./views/Result.vue";
import Report from "./views/Report.vue";
import Home from "./views/Home.vue";
import Error from "./views/Error.vue";
import Workshop from "./views/Workshop.vue";
import { authGuard } from "@auth0/auth0-vue";

const routes = [
  {
    path: "/",
    name: "app",
    component: App,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        beforeEnter: authGuard,
      },
      {
        path: "/participation",
        name: "participation",
        component: Participation,
        beforeEnter: authGuard,
      },
      {
        path: "/result/:assessmentId/workshop/:id",
        name: "result",
        component: Result,
        beforeEnter: authGuard,
      },
      {
        path: "/workshop/:id",
        name: "worshop",
        component: Workshop,
        beforeEnter: authGuard,
        props: route => ({ id: route.params.id, questionId: route.query.questionId })
      },
      {
        path: "/report",
        name: "report",
        component: Report,
        beforeEnter: authGuard,
      },
      {
        path: "/recommendations/:assessmentId",
        name: "recommendations",
        component: Recommendations,
        beforeEnter: authGuard,
      }
    ],
  },
  {
    path: "",
    name: "home",
    component: Home,
  },
  {
    path: "/notfound/:errorStatus?/:errorMessage?",
    name: "notFound",
    component: Error,
    props: true,
  },
  {
    path: "/unauthorized/:errorStatus",
    name: "unauthorized",
    component: Error,
    props: true,
  },
  {
    path: "/forbidden/:errorStatus",
    name: "forbidden",
    component: Error,
    props: true,
  },
  {
    path: "/error/:errorStatus",
    name: "error",
    component: Error,
    props: true,
  },
  {
    path: "/:catchAll(.*)*",
    name: "notfound",
    component: () => import("./views/notfound.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
