<template>
    <Dropdown
        :options="assessments"
        optionLabel="initiative"
        optionValue="id"
        v-model="selectedAssessment"
        @change="selectAssessment"
    />

</template>
<script setup>

import {ref, onBeforeMount, defineProps} from 'vue';
import EventBus from '../../AppEventBus';

const props = defineProps({
        assessments: Array,
        currentAssessmentId: String
});

const selectedAssessment = ref()

onBeforeMount (() => {
    selectedAssessment.value = props.assessments.find(a=>a.id == props.currentAssessmentId).id
})

const selectAssessment = (event) => {
    EventBus.emit("assessment-selected", event.value);
}

</script>