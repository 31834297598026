<template>

    <Dialog
        :visible="opened"
        :style="{width: '450px'}"
        :header="mode == 'create' ? $t('recommendation.createRecommendation') : $t('recommendation.editRecommendation')"
        :modal="true"
        class="p-fluid"
        :closable="false"
    >

        <div class="field">
            <label for="title">{{ $t('recommendation.title') }}</label>
            <InputText id="title" v-model.trim="dialogRecommendation.title" required="true" autofocus :class="{'p-invalid': submitted && !dialogRecommendation.title}" />
            <small class="p-error" v-if="submitted && !dialogRecommendation.title">{{ $t('common.requiredField') }}</small>
        </div>
        <div class="field">
            <label for="theme">{{ $t('recommendation.thematic') }}</label>
            <InputText id="theme" v-model.trim="dialogRecommendation.theme" />
        </div>

        <div class="field">
            <label for="name">{{ $t('recommendation.comment') }}</label>
            <Textarea v-model.trim="dialogRecommendation.comment" rows="3" cols="20" />
        </div>

        <div class="field">
            <label for="benefits">{{ $t('recommendation.benefits') }}</label>
            <Textarea id="benefits" v-model.trim="dialogRecommendation.benefits" rows="3" cols="20" />
        </div>

        <div class="formgrid grid">
            <div class="field col">
                <label for="priority">{{ $t('recommendation.priority') }}</label>
                <InputNumber id="priority" v-model.number="dialogRecommendation.priority" integeronly />
            </div>
            <div class="field col">
                <label for="effort">{{ $t('recommendation.effort') }}</label>
                <InputNumber id="effort" v-model.number="dialogRecommendation.effort" integeronly />
            </div>
        </div>

        <template #footer>
            <Button :label="$t('common.cancel')" icon="pi pi-times" text @click="emit('close')"/>
            <Button :label="mode == 'create' ? $t('common.create') : $t('common.save')" icon="pi pi-check" @click="doAction" autofocus  />
        </template>
    </Dialog>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch } from 'vue';

const submitted = ref(false)
const dialogRecommendation = ref(null)

let props = defineProps({
    opened: Boolean,
    mode: String, // create or update
    recommendation:  {
        type: Object,
        default: function(){ return {} }
    }
})

const emit = defineEmits(['recommendation-updated', 'recommendation-created', 'close'])

watch(() => props.opened, () => {
    if (props.opened) {
        submitted.value = false
        if (props.mode == 'create') {
            dialogRecommendation.value = {
                "title": "",
                "theme": "",
                "comment": "",
                "benefits": "",
                "priority": 0,
                "effort": 0
            }
        } else {
            dialogRecommendation.value = props.recommendation
        }
    }
}, {deep: true})

const doAction = () => {
    if (dialogRecommendation.value.title != '') {
        emit(props.mode == "create" ? 'recommendation-created' : 'recommendation-updated', dialogRecommendation.value)
    }
    submitted.value = true
}

</script>

